import { Component, OnInit } from '@angular/core';
import { first, take } from 'rxjs';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-exhibition',
  templateUrl: './exhibition.component.html',
  styleUrls: ['./exhibition.component.scss']
})
export class ExhibitionComponent implements OnInit {

  exhibitions;
  exhibitionPopular;
  nextExhibition: Array<any> = [];
  constructor(    
    private eventService: EventService,    
  ) { }

  ngOnInit(): void {
    this.getExhibitionAll();
   }
 
   getExhibitionAll() {
    this.eventService.getExhibitions().subscribe((result: any) => {
      console.log(result);
      this.exhibitions = result;
      if (result) {
        this.getExhibitionPopular(result);
      }
    });
  }

  getExhibitionPopular(exhibitions) {
    this.exhibitionPopular = exhibitions;
  }



}
