import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @Input() filterData;
  @Input() sticky: boolean = false;
  @Output() filterDataOutput: EventEmitter<any> = new EventEmitter<any>(); 
  setFilter:boolean = false;
  balance: number = 0;
  defaultFilterData = {categories:{movie:true,music:true,theater:true,exhibition:true}, inBudget:false};

  constructor(
    private _bottomSheet: MatBottomSheet,
    private _formBuilder: FormBuilder,
    private userService: UserService
  ) {}

  getAccountBalance() {
    this.userService.getAccountBalance().subscribe(result => {
      if(result.balance) {
        this.balance = result.balance;
      }
    });
  }

  ngOnInit() {

    this.getAccountBalance();

    let localFilterData = localStorage.getItem("filter");

    if(localFilterData) {
      this.setFilter = true;
      this.filterData = JSON.parse(localFilterData);
    } else {
      this.setFilter = false;
      this.filterData = this.defaultFilterData;
    }







    // if ((localFilterData) && (JSON.stringify(this.filterData) != localFilterData)) {
    //   if (this.defaultFilterData == JSON.parse(localFilterData)) {
    //     console.log("a");
    //   } else {
    //     console.log("b");
    //   }
    //   this.setFilter = true;
    //   this.filterData = JSON.parse(localFilterData);
    // }
    this.filterDataOutput.emit(this.filterData);
  }

  openFilter() {
    let categoriesFormGroup = this._formBuilder.group(this.filterData.categories);
    this._bottomSheet.open(FilterSettingsBottomSheet, {
      data: { categories: categoriesFormGroup, inBudget: this.filterData.inBudget, budget: this.balance }
    }).afterDismissed().subscribe((data) => {
      if(data != undefined) {
        this.filterData = {categories:data.categories.value, inBudget:data.inBudget, budget:this.balance}
        if ((JSON.stringify(this.defaultFilterData.categories) == JSON.stringify(this.filterData.categories)) && (JSON.stringify(this.defaultFilterData.inBudget) == JSON.stringify(this.filterData.inBudget))) {
          this.setFilter = false;
          localStorage.removeItem('filter');
        } else {
          this.setFilter = true;
          localStorage.setItem('filter', JSON.stringify(this.filterData));
        }
        this.filterDataOutput.emit(this.filterData);
      }
    });
  }

}



@Component({
  selector: 'app-filter-settings',
  templateUrl: './filter-settings.component.html',
  styleUrls: ['./filter-settings.component.scss']
})

export class FilterSettingsBottomSheet {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<FilterSettingsBottomSheet>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {}

  dismiss() {
    this._bottomSheetRef.dismiss();
  }

  setFilter(): void {
    this._bottomSheetRef.dismiss(this.data);
  }

}