import { BlockScrollStrategy, ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BookingDetailComponent } from 'src/app/views/booking/booking-detail/booking-detail.component';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {

  @Input() booking: any;
  scrollStrategy: BlockScrollStrategy;

  constructor(    
    public dialog: MatDialog,
    public datepipe: DatePipe
  ) { }

  openBookingDetails() {
    this.dialog.open(BookingDetailComponent, {
      width: '400px',
      data: this.booking,
      panelClass: 'bookingDetail',
      scrollStrategy:this.scrollStrategy,
    });
  }

  dateCheck(date) {
    let today = new Date();
    today.setHours(0,0,0,0);
    let tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    let showTime = new Date (date);
    // showTime.setHours(0,0,0,0);
    let showTimeCheck = new Date (showTime);
    showTimeCheck.setHours(0,0,0,0);
    if(showTimeCheck.toString() == today.toString()) {
      return "Heute";
    } else if (showTimeCheck.toString() == tomorrow.toString()) {
      return "Morgen";
    } else {
      return this.datepipe.transform(date, 'EEEE, dd.MM.').toString();
    }
  }

  isOutdated(date): boolean {
    let today: Date = new Date();
    let ticketDate: Date = new Date(date);
    if(ticketDate > today) {
      return false
    }
    return true;
  }
  
  ngOnInit(): void {
  }



}
