import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-actionbox-map',
  templateUrl: './actionbox-map.component.html',
  styleUrls: ['./actionbox-map.component.scss']
})
export class ActionboxMapComponent {
  
  @Input() eventsToday: Array<any> = [];
  @Input() type: string;
  status: boolean = false;

  constructor(    
    public authService: AuthService,
    ) { }

  toggleActionbox(action) {
    if(action == "open") {
      this.status = true;
    } else {
      this.status = false;
    }
  }

}
