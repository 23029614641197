import { Component, OnInit, Input, SimpleChanges, ViewChild } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-actionbox-showtimes',
  templateUrl: './actionbox-showtimes.component.html',
  styleUrls: ['./actionbox-showtimes.component.scss']
})
export class ActionboxShowtimesComponent implements OnInit {

  @Input() category: string;
  @Input() showtimes: Array<any>;
  @Input() selectedShowtimeDate: string;
  @ViewChild("showtimeTabs", { static: false }) showtimeTabs: MatTabGroup;

  loading: boolean = true;
  status: boolean = false;
  today: Date;
  tomorrow: Date;
  dayaftertomorrow: Date;
  nextYear: Date;
  locations = [];
  showtimeDays = [];
  showtimesList = [];
  showtimesCount = 0;
  nextWeekDays: Array<Date> = [];
  bookingComplete:boolean = false;
  showtimesDataLoaded: Promise<boolean>;

  constructor(
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private eventService: EventService, 
    private router: Router
  ) {}

  toggleActionbox(action) {
    if(action == "open") {
      this.status = true;
    } else {
      this.status = false;
    }
  }

  ngOnInit(): void {
    // TODAY
    this.today = new Date();
    this.today.setHours(0,0,0,0);
    // TOMORROW
    this.tomorrow = new Date(this.today);
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
    // DAYAFTERTOMORROW
    this.dayaftertomorrow = new Date(this.today);
    this.dayaftertomorrow.setDate(this.dayaftertomorrow.getDate() + 2);
    // NEXT YEAR
    this.nextYear = new Date();
    this.nextYear.setFullYear(this.today.getFullYear() + 1);
    this.getNextWeekDays();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['showtimes']) {
      if (this.showtimes != undefined) {
        this.loading = false;
        this.showtimesDataLoaded = Promise.resolve(true);
        this.getShowtimes();
      }
    }
  }

  getLocation(id) {
    const locationItem = this.locations.find(x => x.id == id);
    return location;
  }

  compareDate(date1: Date): number {
    let d1 = new Date(date1);
    d1.setHours(0,0,0,0);
    let same = d1.getTime() === this.today.getTime();
    let same1 = d1.getTime() === this.tomorrow.getTime();
    let same2 = d1.getTime() === this.dayaftertomorrow.getTime();
    if (same) return 0;
    if (same1) return 1;
    if (same2) return 2;
    // Check if the first is less than second
    if (d1 < this.today) return -1;
  }

  async getShowtimes() {
    this.showtimesCount = 0;
    this.showtimesList = [];

    if (this.showtimes) {

    // FOR EACH SHOWTIME
    for (let i = 0; i < this.showtimes.length; i++) {  

      if(this.showtimes[i].data.visible) {

      var date = new Date(this.showtimes[i].data.datetime);
      var now = new Date();
        if ((date > now) || (date > this.today )) {
          this.showtimesCount = this.showtimesCount+1;
      }
          // SET SHOWTIME DAY
          var showtimeDay = new Date(this.showtimes[i].data.datetime);
          showtimeDay.setHours(0,0,0,0);

          // FILTER BY SHOWTIMEDAY
          let dayArray = this.showtimesList.find(x => x.day == showtimeDay.toString());

          let tempSelectedDate = new Date(this.selectedShowtimeDate);
          tempSelectedDate.setHours(0,0,0);
          let selectedTabIndex = this.showtimesList.findIndex(x => x.day == tempSelectedDate.toString());
    
          if(this.showtimeTabs){
            this.showtimeTabs.selectedIndex = selectedTabIndex;
          }

          // IF SHOWTIME DAY EXISTS
          if(dayArray) {


            // CHECK IF LOCATION ALREADY EXISTS AT THE DAY
            // let all = this.showtimesList.reduce((prev, next) => prev.concat(next.locations), []);
            let resultArr = dayArray.locations.find(obj => obj.name === this.showtimes[i].data.location.venue);


            if (resultArr) {



              // LOCATION ALREADY EXISTS AT THE SHOWTIMEDAY
              var temparray = this.showtimesList.find(x => x.day == showtimeDay.toString()).locations;

              await temparray.forEach(element => {

                // ADD FOR CURRENT SHOWTIMELOCATION A NEW SHOWTIME
                if(element.name == this.showtimes[i].data.location.venue) {

                  var date = new Date(this.showtimes[i].data.datetime);
                  var now = new Date();
                  // this.showtimesCount = this.showtimesCount+1;


                    if ((date > now) || (date > this.today )) {
                      element.showtimes.push({
                      "datetime": this.showtimes[i].data.datetime,
                      "showtimeId": this.showtimes[i].id
                    });
                  } 
                }
              });

            } else {

                // LOCATION NOT EXISTING
                this.showtimesList.find(x => x.day == showtimeDay.toString()).locations.push({
                  "name": this.showtimes[i].data.location.venue,
                  "slug": this.showtimes[i].data.location.slug,
                  "city": this.showtimes[i].data.location.city,
                  "showtimes": [{
                    "datetime": this.showtimes[i].data.datetime,
                    "showtimeId": this.showtimes[i].id
                  }]
                });
            }

          // IF SHOWTIME DAY DOES NOT EXISTS
          } else {

            var date = new Date(this.showtimes[i].data.datetime);
            var now = new Date();
            // this.showtimesCount = this.showtimesCount+1;

              if ((date > now) || (date > this.today )) {

              this.showtimesList.push({
                "day" : showtimeDay,
                "locations" : [{
                  "name": this.showtimes[i].data.location.venue,
                  "slug": this.showtimes[i].data.location.slug,
                  "city": this.showtimes[i].data.location.city,
                  "showtimes": [{
                    "datetime": this.showtimes[i].data.datetime,
                    "showtimeId": this.showtimes[i].id
                  }]
                }]
              });

      this.showtimesList.sort(function(a,b){
        var da = new Date(a.day).getTime();
        var db = new Date(b.day).getTime();
        return da < db ? -1 : da > db ? 1 : 0
      });

            }
          }

      }      
    }

    }


  }

  getNextWeekDays() {
    for (let i = 0; i < 7; i++) {
      let newdate = new Date(this.today );
      newdate.setDate(newdate.getDate() + i);
      this.showtimesList.push({'day': newdate,'locations': []});
    }
  }

  removeDuplicates(array) {
    let unique = {};
    array.forEach(function(i) {
      if(!unique[i]) {
        unique[i] = true;
      }
    });
    return Object.keys(unique);
  }

  checkIfNextYear(date):boolean {
    var nextYear = new Date();
    nextYear.setFullYear(this.today.getFullYear() + 1);
    var showtimeYear = date.getFullYear();
    if(showtimeYear == nextYear.getFullYear()) {return true}
    return false
  }

  async bookEvent(showtimeId:string) {
    let currentRoute = this.activatedRoute.snapshot.url[0].path + "/" + this.activatedRoute.snapshot.url[1].path + "/";
    this.router.navigate([currentRoute + "/booking/" + showtimeId + "/"]);
  }

}