import { Component, OnInit } from '@angular/core';
import { MovieEvent } from 'src/app/models/movieevent';
import { EventService } from 'src/app/services/event.service';
import { PersonService } from 'src/app/services/person.service';
import { Person } from 'src/app/models/person';
import { ActivatedRoute } from '@angular/router';
import { SwiperOptions } from 'swiper';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-movie',
  templateUrl: './movie.component.html',
  styleUrls: ['./movie.component.scss']
})
export class MovieComponent implements OnInit {

  moviesHighlights: MovieEvent[] = [];
  moviesPopular: MovieEvent[] = [];
  moviesAction: MovieEvent[] = [];
  moviesDrama: MovieEvent[] = [];
  moviesAnimation: MovieEvent[] = [];
  moviesMostShowtimes: MovieEvent[] = [];
  moviesNew: MovieEvent[] = [];
  moviesUpcomming: MovieEvent[] = [];
  moviesToday: MovieEvent[] = [];
  moviesOthers: MovieEvent[] = [];
  moviesTopRated: any[] = [];
  topLocations: any[] = [];
  date: Date;
  personsPopular: Person[] = [];
  genres: Array<any> = [];
  category: string = this.activatedRoute.snapshot.url[0].path;
  nextMovies: Array<any> = [];
  config: SwiperOptions;

  constructor(
    private eventService: EventService,
    private activatedRoute: ActivatedRoute,
    private personService: PersonService,
    private locationService: LocationService
  ) { }

  ngOnInit(): void {
    this.getMoviesHighlights();
    this.getMoviesPopular();
    this.getMoviesTopRated(); 
    this.getMoviesAction(); 
    this.getMoviesNew();
    this.getPersonsPopular();
    this.getMovieGenres();
    this.getMoviesToday();
    this.getMoviesNext();
    this.getMoviesOthers();
    this.getCinemas();

    let slidesPerViewXS = 1;
    let slidesPerViewMD = 2;
    let slidesPerViewLG = 3;

    this.config = {
      // slidesPerView: this.slides,

      breakpoints: {
        // when window width is >= 320px
        320: {
         slidesPerView: slidesPerViewXS,
         spaceBetween: 20
        },
        // when window width is >= 480px
        480: {
         slidesPerView: slidesPerViewMD,
         spaceBetween: 30
        },
        // when window width is >= 640px
        640: {
         slidesPerView: slidesPerViewLG,
         spaceBetween: 20
        }
      },

      spaceBetween: 20,
      navigation: true,
      mousewheel: {
        forceToAxis: true,
      },
      freeMode: true
    };

  }

  getMoviesPopular() {
    this.eventService.getMoviesPopular().subscribe((result: any) => {
      this.moviesPopular = result;
    });
  }

  getMoviesHighlights() {
    this.eventService.getMoviesHighlighted().subscribe((result: MovieEvent[]) => {
      this.moviesHighlights = result;
    });
  }

  getMoviesNew() {
    var today = new Date();
    today.setHours(0,0,0,0);
    var lastThursday = new Date(today);
    lastThursday.setDate(lastThursday.getDate() - 7);
    var lastThursdayMilliseconds = lastThursday.getTime();
    var todayMilliseconds = today.getTime();
    this.eventService.getMoviesRelease(lastThursdayMilliseconds,todayMilliseconds).subscribe((result: MovieEvent[]) => {
      this.moviesNew = result;
    });
  }

  getMoviesUpcomming() {
    var today = new Date();
    today.setHours(0,0,0,0);
    this.date = today;
    var week = new Date(today);
    week.setDate(week.getDate() + 7);
    this.eventService.getMoviesRelease(today,week).subscribe((result: MovieEvent[]) => {
      this.moviesUpcomming = result;
    });
  }

  getMoviesAction() {
    this.eventService.getEventsByGenre("action", "mostRecentShowtime").subscribe((result: any) => {
      this.moviesAction = result;
      this.getMoviesDrama();
    });
  }

  getMoviesDrama() {
    this.eventService.getEventsByGenre("drama", "mostRecentShowtime").subscribe((result: any) => {
      this.moviesDrama = result;
      this.getMoviesAnimation();
    });
  }

  getMoviesAnimation() {
    this.eventService.getEventsByGenre("animation", "mostRecentShowtime").subscribe((result: any) => {
      this.moviesAnimation = result;
    });
  }

  getMoviesTopRated() {
    this.eventService.getMoviesTopRated().subscribe((result: any[]) => {
      const tempMoviesTopRated = [];
      result.forEach(movie => {
        if(movie.data.ratings[0] && movie.data.ratings[0].value > 6) {
          tempMoviesTopRated.push(movie);
        }
      });
      tempMoviesTopRated.sort((a,b) => b.data.ratings[0].value.localeCompare(a.data.ratings[0].value));
      this.moviesTopRated = tempMoviesTopRated;
    });
  }

  getPersonsPopular() {
    this.personService.getPersonsPopular("actor").subscribe((result: Person[]) => {
      this.personsPopular = result;
    });
  }

  getMovieGenres() {
    this.eventService.getGenres("movie").subscribe((result: any) => {
      this.genres = result;
    });
  }

  getMoviesToday() {
    // this.eventService.getMoviesToday().subscribe((result: any) => {
    //   this.moviesToday = result;      
    // });
  }

  getMoviesNext() {
    this.eventService.getNextMovies().subscribe((result:any) => {
      this.nextMovies = result;
      let today = new Date();
      today.setHours(23,59,0,0);
      result.forEach(element => {
        if(new Date(element.data.datetime) < today) {
          this.moviesToday.push(element);
        }
      });
    });
      

    

  

    // this.eventService.getNextMovies().subscribe((result:any) => {
    //   if(result ) {          
    //                   this.nextMovies.push(result);    
    //   }
    // });



    // this.eventService.getNextEvents().subscribe((result:any) => {
    //   this.nextEvents = result;
    //   let today = new Date();
    //   today.setHours(23,59,0,0);
    //   result.forEach(element => {
    //     if(new Date(element.data.datetime) < today) {
    //       this.eventsToday.push(element);
    //     }
    //   });
    //   this.getExhibitions();
    // });

  }


  getMoviesOthers() {
    this.eventService.getEventsWithoutGenre("movie", "mostRecentShowtime").subscribe((result: any) => {
      this.moviesOthers = result;
    });
  }

  getCinemas() {
      this.locationService.getTopLocationsByType("movie").then((result:any) => {
        if(result.docs) {
          this.topLocations = result.docs;
        }
      });
  }
  

}
