import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-balance',
  templateUrl: './account-balance.component.html',
  styleUrls: ['./account-balance.component.scss']
})
export class AccountBalanceComponent implements OnInit, OnChanges {

  @Input() value: number;
  @Input() maxValue: number = -1;
  @Input() style: string;
  @Input() addCreditsLink: boolean = false;
  
  progress:number = 0;
  loading: boolean = true;

  constructor() { }

  ngOnInit(): void {
    if(this.maxValue == 0) {  
      this.percentCalculation();
    } 
  }

  ngOnChanges(): void {
    if(this.maxValue) {  
      this.percentCalculation();
      this.loading = false;
    } 
  }

  percentCalculation(): void {
    if(this.maxValue > 0) {
      this.progress = this.value / (this.maxValue / 100);
    } else {
      this.progress = 0;
    }
  }

}
