import { Component, OnInit } from '@angular/core';
import { City } from 'src/app/models/city';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-intro-complete',
  templateUrl: './intro-complete.component.html',
  styleUrls: ['./intro-complete.component.scss']
})
export class IntroCompleteComponent implements OnInit {

  cities: Array<City> = [];
  selectedCity: string = "muenchen";

  constructor(    
    private eventService: EventService
    ) { }

  ngOnInit(): void {
    this.getCities();
  }

  getCities() {
    this.eventService.getCities().subscribe((result:[City]) => {
      this.cities = result;
      console.log(result);
    });
  }

}
