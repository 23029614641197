import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { first, map, mergeAll, mergeMap, shareReplay, switchMap, take } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(
    private db: AngularFirestore,
    private fns: AngularFireFunctions
    ) {} 

    // GENERAL
    
    getEvent(type:string, document:string): Observable<any> {
      return this.db.collection("events", ref => ref.where("type",">",type)).doc(document)      
      .snapshotChanges().pipe(map(action => {
        const data = action.payload.data(); 
        const id = action.payload.id;    
        return { id, data };   
      }));
    }

    getEventById(document:string) {
      return this.db
      .collection("events")
      .doc(document)
      .get().pipe(map(action => {
        const data = action.data(); 
        const id = action.id;    
        return { id, data };   
      }));
    }

    getEventsByGenre(genre:string, sort?:string, limit?:number): Observable<any> {
      if(!sort) {
        sort = "name";
      }
      if(!limit) {
        limit = 1000;
      }
      return this.db.collection('genres').doc(genre).collection('events', ref => ref.orderBy(sort, 'asc').limit(limit))
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data(); 
          const id = a.payload.doc.id;    
          return { id, data };      
        }))
      );
    }



    getEventsWithoutGenre(type:string, sort?:string, limit?:number): Observable<any> {
      if(!sort) {
        sort = "name";
      }
      if(!limit) {
        limit = 1000;
      }
      return this.db.collection('events', ref => ref.where("visible","==",true).where("type","==",type).where("genres", "==", []).orderBy(sort, 'asc').limit(limit))
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data(); 
          const id = a.payload.doc.id;    
          return { id, data };      
        }))
      );
    }
  
    getEventsByLocation(locationid:string): Observable<any> {
      return this.db.collection('locations').doc(locationid).collection('events', ref => ref.orderBy('mostRecentShowtime','asc'))
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data(); 
          const id = a.payload.doc.id;    
          return { id, data };       
        }))
      );
    }

    getEventsByDate(minDate, maxDate): Observable<any> {
      return this.db.collectionGroup("showtimes",ref => ref.where("visible","==",true).where("status","==","AVAILABLE").where("eventType","in",['music', 'movie', 'theater']).where("datetimeUTC",">",minDate).where("datetimeUTC","<",maxDate).orderBy('datetimeUTC','asc'))
        .snapshotChanges()
        .pipe(
          map(actions => actions.map((action:any) => {
            const id = action.payload.doc.ref.parent.parent.id;
            const data = action.payload.doc.data(); 
              return {id, data};
            }))
        )
    }

    getCities() {
      return this.db.collection("cities").snapshotChanges().pipe(
        map(actions => actions.map((a:any) => {
          return {
            id: a.payload.doc.id,
            location: a.payload.doc.data().location,
            name: a.payload.doc.data().name,
            tmId: a.payload.doc.data().tmId,
            isId: a.payload.doc.data().isId,
          };
        }))
      );
    }

    getRecommendations(): Observable<any> {
      return this.db.collection("events", ref => ref.where("visible","==",true).orderBy('popularity','desc').limit(20))
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data(); 
          const id = a.payload.doc.id;    
          return { id, data };      
        }))
      );
    }
 




    

  // MOVIES
  // ##########################################################################################################

  // getMovies() {
  //   return this.db.collection('events',ref => ref.where('type','==', 'movie')).snapshotChanges();
  // }



  getMoviesHighlighted(): Observable<any> {
    return this.db.collection("events", ref => ref.where('type','==','movie').where("visible","==",true).orderBy('showtimeCount','desc').limit(10))
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();           
          const id = a.payload.doc.id;    
          return { id, data };
        }))
      );
  }

  getMoviesNew(): Observable<any> {
    return this.db.collection('events', ref => ref.where('type','==','movie').where("visible","==",true).orderBy("releaseDate",'desc').limit(5))
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data(); 
          const id = a.payload.doc.id;    
          return { id, data };
        }))
      );
  }

  getMoviesRelease(today,week): Observable<any>  {
    return this.db.collection('events', ref => ref.where('type','==','movie').where("visible","==",true).where("releaseDate",">",today).where("releaseDate","<",week).orderBy("releaseDate",'desc').limit(10))
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data(); 
          const id = a.payload.doc.id;    
          return { id, data };
        }))
      );
  }

  getMoviesTopRated(): Observable<any> {
    return this.db.collection("events", ref => ref.where('type','==','movie').where("visible","==",true))
    .snapshotChanges()
    .pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;    
        return { id, data };
      }))
    );
  }




  getMoviesPopular() {
    return this.db.collection('events',ref => ref.where('type','==','movie').where("visible","==",true).where('popularity','>',1).orderBy('popularity','desc').limit(15))
    .snapshotChanges()
    .pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data(); 
        const id = a.payload.doc.id;    
        return { id, data };
      }))
    );
  }

  
  getMoviesToday(): Observable<any> {
    let today = new Date().toISOString();
    return this.db.collection('events',ref => ref.where("type","==","movie").where("visible","==",true).where("mostRecentShowtime",">",today).orderBy('mostRecentShowtime','asc').limit(15))
    .snapshotChanges()
    .pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data(); 
        const id = a.payload.doc.id;   
        return {id, data};
      }))
    );
  }








  // // what you can do:
  // getMarkets()
  //   .map(obj => obj.data.map(market => market.name)) //1
  //   .switchMap(marketNames =>
  //     Observable.forkJoin( // 4
  //       ...marketNames.map(market => getTicker(market) // 2
  //       .map(ticker => +ticker.data.lastTrade))) // 3
  //   )
  //   .subscribe(trades => console.log(trades.join(', ')));


  




  // getNextEvents(): Observable<any> {
  //   let today = new Date().toISOString();  
  //   return this.db.collectionGroup("showtimes",ref => ref.where("visible","==",true).where("status","==","AVAILABLE").where("datetime",">",today).orderBy('datetime','asc').limit(50))
  //     .snapshotChanges()
  //     .pipe(
  //       map(actions => actions.map((action:any) => {
  //         console.log("####");
  //         console.log(action.payload.doc.data().datetime);
  //         return action.payload.doc.ref.parent.parent.id;
  //       })),
  //       mergeMap((elements:any) => elements.map((element:any) =>{
  //         return this.getEventById(element).pipe(map(element => element));
  //       })),
  //       mergeAll()
  //     )
  // }

  getNextEvents(): Observable<any> {
    let today = new Date().toISOString();  
    return this.db.collectionGroup("showtimes",ref => ref.where("visible","==",true).where("status","==","AVAILABLE").where("eventType","in",['music', 'movie', 'theater']).where("datetime",">",today).orderBy('datetime','asc').limit(500))
      .snapshotChanges()
      .pipe(
        map(actions => actions.map((action:any) => {
          const id = action.payload.doc.ref.parent.parent.id;
          const data = action.payload.doc.data(); 
            return {id, data};
          }))
      )
  }

  getNextMovies(): Observable<any> {
    let today = new Date().toISOString();    
    return this.db.collectionGroup("showtimes",ref => ref.where("visible","==",true).where("channel","in",['cinetixx', 'kinoheld']).where("status","==","AVAILABLE").where("datetime",">",today).orderBy('datetime','asc').limit(100))
    .snapshotChanges()
    .pipe(
      map(actions => actions.map((action:any) => {
        const id = action.payload.doc.ref.parent.parent.id;
        const data = action.payload.doc.data(); 
          return {id, data};
        }))
    )
}

getNextMusic(): Observable<any> {
  let today = new Date().toISOString();    
  return this.db.collectionGroup("showtimes",ref => ref.where("visible","==",true).where("status","==","AVAILABLE").where("eventType","in",['music']).where("datetime",">",today).orderBy('datetime','asc').limit(100))
  .snapshotChanges()
  .pipe(
    map(actions => actions.map((action:any) => {
      const id = action.payload.doc.ref.parent.parent.id;
      const data = action.payload.doc.data(); 
        return {id, data};
      }))
  )
}

getNextTheater(): Observable<any> {
  let today = new Date().toISOString();    
  return this.db.collectionGroup("showtimes",ref => ref.where("visible","==",true).where("status","==","AVAILABLE").where("eventType","in",['theater']).where("datetime",">",today).orderBy('datetime','asc').limit(100))
  .snapshotChanges()
  .pipe(
    map(actions => actions.map((action:any) => {
      const id = action.payload.doc.ref.parent.parent.id;
      const data = action.payload.doc.data(); 
        return {id, data};
      }))
  )
}

getNextExhibition(): Observable<any> {
  let today = new Date().toISOString();    
  return this.db.collectionGroup("showtimes",ref => ref.where("visible","==",true).where("status","==","AVAILABLE").where("eventType","in",['exhibition']).where("datetime",">",today).orderBy('datetime','asc'))
  .snapshotChanges()
  .pipe(
    map(actions => actions.map((action:any) => {
      const id = action.payload.doc.ref.parent.parent.id;
      const data = action.payload.doc.data(); 
        return {id, data};
      }))
  )
}





  getMusicEvents(type:string): Observable<any> {
    return this.db.collection('events',ref => ref.where("type","==","music").where("visible","==",true).where("channels.muenchenticket","!=","null"))
    .snapshotChanges()
    .pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data(); 
        const id = a.payload.doc.id;   
        return {id, data};
      }))
    );
  }

  

  // getMusicToday(): Observable<any> {
  //   let today = new Date().toISOString();
  //   return this.db.collection('events',ref => ref.where("type","==","music").where("visible","==",true).where("mostRecentShowtime",">",today).orderBy('mostRecentShowtime','asc').limit(15))
  //   .snapshotChanges()
  //   .pipe(
  //     map(actions => actions.map(a => {
  //       const data = a.payload.doc.data(); 
  //       const id = a.payload.doc.id;   
  //       return {id, data};
  //     }))
  //   );
  // }


  // getCredits(collection:string, type:string, document:string): Observable<any> {
  //   return this.db.collection(collection).doc(document).collection("credits",ref => ref.orderBy('order','asc'))
  //     .snapshotChanges()
  //     .pipe(
  //       map(actions => actions.map(a => {
  //         const data = a.payload.doc.data(); 
  //         const id = a.payload.doc.id;   
  //         return {id, data};
  //       }))
  //     );
  // }

  getGenres(type:string): Observable<any> {
    return this.db.collection('genres',ref => ref.where("type","==",type).where("visible","==",true).orderBy('name','asc'))
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data(); 
          const id = a.payload.doc.id;   
          return {id, data};
        }))
      );
  }

  getGenre(document:string): Observable<any> {
    return this.db.collection("genres").doc(document)      
    .snapshotChanges().pipe(map(action => {
      return action.payload.data();
    }));
  }

// ############
// ############
// ############
// ############
// ############
// ############
// ############
// ############





    // const collection = this.firestore.collection('users');
    // return  collection.valueChanges.pipe(
    //   mergeMap( (users: User[]) =>  
    //              this.firestore.doc(`stories/${users[0].uid}`).
    //                       valueChanges().pipe(map(
    //                         (storie) => Object.assign({}, {users[0].uid, ...users[0], ...stories}
    //                     ))
    //          ))
    //  );







    

  getAllShowtimes() {
    return this.db.collectionGroup('showtimes',ref => ref.orderBy('datetime', 'asc')).valueChanges();
  }
    





  getEventShowtimeById(eventId:string, showtimeId:string) {
    return this.db.collection("events").doc(eventId).collection('showtimes').doc(showtimeId)
    .snapshotChanges().pipe(map(action => {
      const data = action.payload.data(); 
      const id = action.payload.id;    
      return { id, data };   
    }));
  }

  getEventShowtimes(docRef) {
    return this.db.collection("events").doc(docRef).collection('showtimes',ref => ref.where("visible","==",true).orderBy('datetime', 'asc'))
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data(); 
          const id = a.payload.doc.id;   
          return {id, data};
        }))
      );
  }

 
































  // ### GET DATA FROM FIRESTORE ##############################################################################
  // ##########################################################################################################

  // getDocument(collection:string, document:string) {
  //   return new Promise<any>((resolve, reject) => {
  //     this.db
  //       .collection(collection)
  //       .doc(document)
  //       .get()
  //       .subscribe(res => resolve(res), err => reject(err));
  //   });
  // }

  // getDocumentBySlug(collection:string, slug:string) {
  //   return this.db.collection(collection, ref => ref.where('slug', '==', slug)).snapshotChanges().pipe(
  //     map(actions => actions.map(a => {
  //       const data = a.payload.doc.data(); 
  //       const id = a.payload.doc.id;   
  //       return {id, data};
  //     }))
  //   );
  // }

  // getCollection(collection:string) {
  //   return this.db.collection(collection).snapshotChanges().pipe(
  //     map(actions => actions.map(a => {
  //       const data = a.payload.doc.data(); 
  //       const id = a.payload.doc.id;    
  //       return { id, data };
  //     }))
  //   );
  // }












  // ##########################################################################################################
  // ### MUSIC
  // ##########################################################################################################

  getMusicPopular() {
    return this.db.collection('events',ref => ref.where('type', '==', 'music').where("visible","==",true).orderBy('popularity','desc').limit(20))
    .snapshotChanges()
    .pipe(
      map(actions => actions.map((a:any) => {        
        const data = a.payload.doc.data(); 
        const id = a.payload.doc.id;    
        return { id, data };
      }))
    );
  }

  getMusicGenre(genre:string) {
    const genreRef = {"id":"1","name":"Rock & Pop","slug":"rock---pop"};

    return this.db.collection('events',ref => ref.where('type', '==', 'music').where('genres', 'array-contains', '{"id":1,"name":"Rock & Pop","slug":"rock---pop"}').orderBy('mostRecentShowtime','asc'))
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          return a.payload.doc.data();        
        }))
      );
  }



  // ##########################################################################################################
  // ### THEATER
  // ##########################################################################################################

  getTheaterPopular() {
    return this.db.collection('events',ref => ref.where("type","==", "theater").where("visible","==",true).orderBy('popularity','desc').limit(15))
    .snapshotChanges()
    .pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data(); 
        const id = a.payload.doc.id;    
        return { id, data };     
      }))
    );
  }

  getTheaterNext() {
    return this.db.collection('events',ref => ref.where("type","==", "theater").where("visible","==",true).orderBy('mostRecentShowtime','asc').limit(15))
    .snapshotChanges()
    .pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data(); 
        const id = a.payload.doc.id;    
        return { id, data };     
      }))
    );
  }

  getTheaterAll() {
    return this.db.collection('events',ref => ref.where("type","==", "theater").where("visible","==",true).orderBy('mostRecentShowtime','asc'))
    .snapshotChanges()
    .pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data(); 
        const id = a.payload.doc.id;    
        return { id, data };     
      }))
    );
  }



 // ##########################################################################################################
  // ### SPORT
  // ##########################################################################################################

  getSportPopular() {
    return this.db.collection('events',ref => ref.where("type", "==", "sport").orderBy('mostRecentShowtime','asc').limit(15))
    .snapshotChanges()
    .pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data(); 
        const id = a.payload.doc.id;    
        return { id, data };     
      }))
    );
  }

  // ##########################################################################################################
  // ### EXHIBITION
  // ##########################################################################################################

  getExhibitions() {
    return this.db.collection('events',ref => ref.where("type", "==", "exhibition").where("visible","==",true).orderBy('popularity','desc'))
    .snapshotChanges()
    .pipe(
      map(actions => actions.map((a:any) => {
        const data = a.payload.doc.data(); 
        const id = a.payload.doc.id;    
        return { id, data };     
      }))
    );
  }



  updateShowtimesAvailability(eventId:string, showtimeId:string) {
    const updateShowtimesAvailability = this.fns.httpsCallable('changeShowtimesAvailability'); 
    return updateShowtimesAvailability({
      "eventId": eventId,
      "showtimeId": showtimeId
    }).toPromise();
  }


  // bookEvent(eventId:string, showtimeId:string, seatId:string): Observable<any>{
  //   const bookEvent = this.fns.httpsCallable('bookEvent');
  //   return bookEvent({
  //     eventId: eventId,
  //     showtimeId: showtimeId,
  //     seatId: seatId
  //   });
  // }



  getPriceCategories(eventId:string, showtimeId:string) {
    const getPriceCategories = this.fns.httpsCallable('getPriceCategories'); 
    return getPriceCategories({
      "eventId": eventId,
      "showtimeId": showtimeId
    }).toPromise();
  }

  mapSeatsResult(channel:string, seatResult:any) {



    let sections: Array<any> = [];
    let location: any;

    switch (channel) {
      case "cinetixx":

       location = {
        width: seatResult[0].width,
        height: seatResult[0].height,
        sections: seatResult     
      }
        return location

        break;
      case "muenchenticket":

        seatResult.sections.forEach(element => {

          let tempSeats: Array<any> = [];
          
          element.seats.forEach(seat => {
                tempSeats.push({
                  id: seat.id,
                  x: seat.left,
                  y: seat.top,
                  width: seat.width,
                  height: seat.height,
                  isAvailible: seat.free
                });
          });
          sections.push({
            x: element.outline[0].x,
            y: element.outline[0].y,
            width: element.outline[2].x - element.outline[0].x,
            height: element.outline[2].y - element.outline[0].y,
            seats: tempSeats
          });

        });


  



         location = {
          width: seatResult.size.width,
          height: seatResult.size.height,
          sections: sections     
        }

        return location


        break;
      default:
        console.log("No such day exists!");
        break;
    }
  
  }
  
  reserveEvent(eventId:string, showtimeId:string, chosenAvailabilities:Array<any>): Observable<any>{
    const reserveEvent = this.fns.httpsCallable('reserve');
    return reserveEvent({
      showtimeId: showtimeId,
      eventId: eventId,
      chosenAvailabilities: chosenAvailabilities
    });
  }


  
  checkoutEvent(session:string, signature:string): Observable<any>{
    const checkoutEvent = this.fns.httpsCallable('checkout');
    return checkoutEvent({
      session: session,
      signature: signature
    });
  }
  


  getEventsWithSpecialPrice(): Observable<any> {
    return this.db.collection("events",ref => ref.where("visible","==",true).where("hasSpecialPrice","==",true).orderBy('mostRecentShowtime','asc'))
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data(); 
          const id = a.payload.doc.id;    
          return { id, data };      
        }))
      )
  }

  getEventsNew(): Observable<any> {
    return this.db.collection("events",ref => ref.where("visible","==",true).orderBy('visibleNewAt','desc').limit(25))
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data(); 
          const id = a.payload.doc.id;    
          return { id, data };      
        }))
      )
  }

  





  getShowtimesWithSpecialPrice(): Observable<any> {
    let today = new Date();
    return this.db.collectionGroup("showtimes",ref => ref.where("visible","==",true).where("status","==","AVAILABLE").where("hasSpecialPrice","==",true).where("datetime",">",today).orderBy('datetime','asc'))
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data(); 
          const id = a.payload.doc.id;    
          return { id, data };      
        }))
      )
  }






  // ##########################################################################################################
  // ### TESTAREA
  // ##########################################################################################################


  // showMonsterEvents(): Observable<any> {
  //   return this.db.collectionGroup("showtimes",ref => ref.where("visible","==",true).where("status","==","AVAILABLE").where("location.type","in",['music', 'movie']).where("datetime",">",today).orderBy('datetime','asc'))
  //     .snapshotChanges()
  //     .pipe(
  //       map(actions => actions.map((action:any) => {
  //         const id = action.payload.doc.ref.parent.parent.id;
  //         const data = action.payload.doc.data(); 
  //           return {id, data};
  //         }))
  //     )
  // }




  // getAllEvents(): Observable<any> {
  //   return this.db.collection('events')
  //   .snapshotChanges()
  //   .pipe(
  //     map(actions => actions.map((a:any) => {
  //       const name = a.payload.doc.data().name; 
  //       const id = a.payload.doc.id;    
  //       return { id, name };      
  //     }))
  //   );
  // }

  // delteEvent(id) {
  //   this.db.collection('events').doc(id).delete();
  // }


  // getAllSubEventsFromGenre(genre): Observable<any> {
  //   return this.db.collection("genres",).doc(genre).collection("events")
  //     .snapshotChanges()
  //     .pipe(
  //       map(actions => actions.map((action:any) => {
  //         const id = action.payload.doc.ref.id;
  //         const genre = action.payload.doc.ref.parent.parent.id;
  //         const data = action.payload.doc.data(); 
  //           return {id, data,genre};
  //         }))
  //     )
  // }

  // showLocationEvents(): Observable<any> {
  //   let today = new Date();
  //   return this.db.collection("locations",ref => ref.where("visible","==",true))
  //     .snapshotChanges()
  //     .pipe(
  //       map(actions => actions.map((action:any) => {
  //         const id = action.payload.doc.id;    
          
  //         this.db.collection("locations").doc(id).collection("events").snapshotChanges()
  //         .pipe(
  //           map(actions => actions.map((actionEvent:any) => {
  //             const id2 = actionEvent   
  //             return {id2};

  //           })));
  //         }))
  //     )
  // }




  getAllMovies(): Observable<any> {
    return this.db.collection("events", ref => ref.where('type','==','movie'))
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const id = a.payload.doc.id;    
          return { id };
        }))
      );
  }


}


