import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

  passwordForm: UntypedFormGroup;
  message:string;
  
  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService
  ) { 
    this.passwordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  resetPassword(value:any) {
    if (!this.passwordForm.valid) return;
    this.authService.sendPasswordResetEmail(value).then(result => {
    });
    this.message = "E-Mail wurde verschickt, falls ein Account dazu existiert.";

  }

  ngOnInit(): void {
  }

}