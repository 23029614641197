import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from 'src/app/services/user.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { error } from 'console';
import {MatSnackBar} from '@angular/material/snack-bar';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})

export class AccountComponent implements OnInit {
  
  user$;
  userAuthData$;
  userId: string;
  returnUrl: string;
  balance: number = 0;
  coins: any;
  issueDate: Date;
  initialAmount: number = 0;
  bookings: Array<any> = [];
  bookingsOutdated: Array<any> = [];
  personalDataForm : UntypedFormGroup;
  loading:boolean = true;
  reactivateloading:boolean = false;
  alreadyadded : boolean = false;
  subscription;
  selectedSubscriptionPlan;
  membershipNumber: number;
  scheduledSubscriptionChangeDate: Date;
  scheduledSubscriptionChangePlan: string;
  activeTabIndex:number = 0;

  constructor(
    public authService: AuthService,
    private route: ActivatedRoute,
    private userService: UserService,
    private _location: Location,
    private formBuilder: UntypedFormBuilder,
    private checkoutService: CheckoutService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private analytics: AngularFireAnalytics,
  ) {
    var paramTab = this.route.snapshot.queryParamMap.get('tab')||"";
    if(paramTab == "personal-data") {
      this.activeTabIndex = 1;
    } else if(paramTab == "subscription") {
      this.activeTabIndex = 2;
    } 
  }

  async ngOnInit() {

    this.personalDataForm = this.formBuilder.group({
      salutation: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      birthday: [''],
      address: this.formBuilder.group({
        line1: ['', Validators.required],
        zip: ['', Validators.required],
        city: ['', Validators.required],
        country: [{value: 'DE-de', disabled: true}, Validators.required]
      })
    });

    this.user$ = await this.authService.getUser();
    this.userAuthData$ = await this.authService.getUserAuthData();
    this.user$.subscribe((user) => {
      this.personalDataForm.patchValue(user);   
      this.subscription = user?.subscription;
      this.scheduledSubscriptionChangeDate = user?.subscription?.scheduledSubscriptionChange?.changesOn;
      this.scheduledSubscriptionChangePlan = user?.subscription?.scheduledSubscriptionChange?.plan;
      this.membershipNumber = user?.membershipNumber;
      this.checkoutService.getSubscriptionPlans().subscribe(result => {
        this.loading = false;
        if(result) {
          this.selectedSubscriptionPlan = result.data[this.subscription?.plan];
          this.issueDate = new Date(this.subscription?.currentTermEnd);
          // this.issueDate.setDate(this.issueDate?.getDate() + 1);
        }
      });
      this.userId = user?.uid;
      this.getBookings(this.userId);     
    });      
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.getAccountBalance();
  }

  getSelectedIndex(): number {
    return this.activeTabIndex
  }

  onTabChange(event: MatTabChangeEvent) {
      this.activeTabIndex = event.index
  }

  connectWithSpoitfy() {
    this.authService.getSpotifyAuthAccessToken().subscribe((result) => {
      this.authService.spotifySignin(result);
    });
  }

  getAccountBalance() {
    this.userService.getAccountBalance().subscribe(result => {
      this.balance = result.balance;
      this.initialAmount = result.initialAmount;
      this.coins = result.coins;
      let tempCoinsEntry = result?.coins.find(x => x.item == this.subscription?.price);
    });
  }



  getBookings(userId:string) {
    this.loading = true;
    this.userService.getBookings(userId).subscribe((result) => {
      if (result.length > 0) {
        this.addBookings(result);      
        this.loading = false;
      }
    });
  }
  

  addBookings(bookings) {
    if (!this.alreadyadded) {
      var today = new Date();
      this.alreadyadded = true;
      bookings.forEach(element => {
        if(new Date(element?.data?.showtime?.datetime) > today) {
          this.bookings.push(element);
        } else {
          this.bookingsOutdated.unshift(element);
        }
      });
    }
  }




  backLink() {
    this._location.back();
  }

  updateCustomer() {
    var formRawValue = this.personalDataForm.getRawValue();
    if (!this.personalDataForm.valid) return
    this.loading = true;
    this.checkoutService.updateCustomer(
      formRawValue
    ).then(result => {
      this.loading = false;
    }).catch(err => console.error(err));
  }

  
  reactivateSubscription() {
    this.reactivateloading = true;
    this.checkoutService.reactivateSubscription().then(result => {
        this.analytics.logEvent('subscription_reactivated');
        this._snackBar.open("Mitgliedschaft wurde reaktiviert.","", {
          duration: 4000,
        });
        this.reactivateloading = false;
    }).catch(err => {
      switch (err.details?.errorCode) {
        case "PaymentMethodNotFound":
          this.router.navigate(["/account/paymentinfo"],{ queryParams: { case: 'reactivate' }});
          break;
        default:
          console.error(err);
          break;
      } 
    });
  }

  // signOut() {
  //   this.authService.signOut().then((result:any) => {
  //     if(result) {
  //       console.log(result);
  //       this.router.navigate([ "/start"]);
  //       this._snackBar.open("Du bist ausgeloggt.","", {
  //         duration: 4000,
  //       });
  //     }
  //   });
  // }

}