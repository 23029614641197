import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'product-selection-bottomsheet',
  templateUrl: './product-selection-bottomsheet.component.html',
  styleUrls: ['./product-selection-bottomsheet.component.scss']
})
export class ProductSelectionBottomSheet implements OnInit {

  subscriptionPlans = [];

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<ProductSelectionBottomSheet>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {}

  ngOnInit(): void {
    Object.entries(this.data.subscriptionPlans).forEach(entry => {
      const [key, value] = entry;
      this.subscriptionPlans.push({
        id: key,
        data: value
      });
    });
  }

  dismiss() {
    this._bottomSheetRef.dismiss();
  }

  openLink(subscriptionPlanId: string): void {
    this._bottomSheetRef.dismiss(subscriptionPlanId);
  }

}