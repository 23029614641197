import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detailpage-layout',
  templateUrl: './detailpage-layout.component.html',
  styleUrls: ['./detailpage-layout.component.scss']
})
export class DetailpageLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }



}
