import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from './auth.service';
import { map, Observable } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(    
    private fns: AngularFireFunctions,    
    private db: AngularFirestore,
    private authService: AuthService
    ) { }

  setCurrentCity(city) {
    localStorage.setItem('currentCity', city)
  }

  getCurrentCity() {
    return localStorage.getItem('currentCity');
  }

  getFavorites(): Observable<any> {
    return 
  }

  getBookings(userId:string): Observable<any> {  
    return this.db.collection("users").doc(userId).collection("bookings",ref => ref.orderBy('showtime.datetime','asc'))
      .snapshotChanges().pipe(
        map(actions => actions.map((action:any) => {
          const data = action.payload.doc.data(); 
          const id = action.payload.doc.id;    
          return { id, data };      
        })
      )
    );
  }

  // getBookingById(bookingId:string): Observable<any> {  
  //   return this.db.collection("users").doc(userId).collection("bookings",ref => ref.orderBy('showtime.datetime','asc'))
  //     .snapshotChanges().pipe(
  //       map(actions => actions.map((action:any) => {
  //         const data = action.payload.doc.data(); 
  //         const id = action.payload.doc.id;    
  //         return { id, data };      
  //       })
  //     )
  //   );
  // }

  getAccountBalance(): Observable<any>{
    const customerAccountBalance = this.fns.httpsCallable('getCustomerAccountBalance');
    return customerAccountBalance({});
  }


  setFavorite(type:string, id:string): Promise<any> {
    const userRef = this.authService.user$;


    // const userData = this.afs.doc(`users/${userRef.uid}`);

    console.log("###########");
    console.log(type);
    console.log(id);
    console.log("###########");
    // console.log(userRef.user.uid);
    console.log("###########");

    // this.user$ = this.afAuth.authState.pipe(
    //   switchMap(user => {
    //     if (user && user.emailVerified) {
    //         return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
    //     } else {
    //       return of(null);
    //     }
    //   })
    // )

    //   userRef.collection('favorites').doc("Test").set({
    //     'name': "Testname",
    //     'slug': "Test"
    //   });
    // userRef.update(data).then(function() {
    // }).catch(function(error){
    //   console.log("Error adding subcollections to Firestore: " + error);
    // });
    return
  }

}
