import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { EventService } from 'src/app/services/event.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {

  loading:boolean = true;
  user$;
  eventId:string;
  eventType:string;
  event;
  showtimeId:string;
  showtime;
  allowedticketsPerBooking: number = 1;
  errorMessage: string;
  numberTickets: number = 1;
  bookingStep:number = 1;
  priceCategories: Array<any> = [];
  selectedSeats: Array<any> = [];
  selectedPriceCategory: any;
  shippingMethod:string;
  price: number;
  session: string;
  signature: string;
  accountBalance;
  eventToExpensive:boolean = false;
  creditsRequired;
  seatingPlan:any;

  constructor(    
    public authService: AuthService,
    private eventService: EventService,
    private checkoutService: CheckoutService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    ) {
      this.route.paramMap.subscribe( paramMap => {
        this.eventId = paramMap.get('id');
        this.showtimeId = paramMap.get('showtimeid');
      })
    }

  async getUser(): Promise<void> {
    this.user$ = await this.authService.getUser();
    this.user$.subscribe((user) => {
      if (user?.subscription?.plan && user?.subscription?.status != "cancelled") {
        this.getAllowedTicketsPerBooking(user?.subscription?.plan);
      } else {
        let currentRoute = this.route.snapshot.url[0].path + "/" + this.route.snapshot.url[1].path + "/";
        this.router.navigate([currentRoute + "/booking/" + this.showtimeId + "/preview"]);
      }
    });
  }

  checkAccountBalance() {
    this.userService.getAccountBalance().subscribe(result => {
      this.accountBalance = result.balance;
      if (this.price > result.balance) {
        this.eventToExpensive = true;
        this.creditsRequired = this.price - result.balance;
        this.loading = false;
        this.bookingStep = 3;

      } else {
        this.eventToExpensive = false;
        this.bookingStep = 3;
        this.loading = false;

      }
    });
  }


  getEventDetails(): void {
    this.eventService.getEvent("movie", this.eventId).subscribe((result:any) => {
      this.event = result.data;
      this.getShowtimeDetails();
    });
  }

  getShowtimeDetails(): void {
    this.eventService.getEventShowtimeById(this.eventId, this.showtimeId).subscribe((result:any) => {
      this.showtime = result.data;
      this.getPriceCategories(this.eventId, this.showtimeId);
    });
  }

  async getPriceCategories(eventId:string, showtimeId:string) {
    this.eventService.getPriceCategories(eventId, showtimeId).then(result => {
      console.log(result);
      this.eventType = result.type;
      if (result.priceCategories.length > 0) {
        this.seatingPlan = result.seatingPlan;
        this.priceCategories = result.priceCategories;
        this.selectedPriceCategory = this.priceCategories[0];
      } else {
        this.errorMessage = "Das Event ist zu dieser Uhrzeit leider ausgebucht.";
      }
      this.loading = false;
    });
  }

  getAllowedTicketsPerBooking(subscriptionPlanId:string): void {
    this.checkoutService.getSubscriptionPlans().subscribe((result:any) => {
      if(result.data[subscriptionPlanId]) {
        this.allowedticketsPerBooking = result.data[subscriptionPlanId].maxPersons;
      } else {
        this.router.navigate(['/account']);
      }
    });
  }

  addTicket() {
    if(this.numberTickets < this.allowedticketsPerBooking) {
      this.numberTickets++
    }
  }

  removeTicket() {
    if(this.numberTickets > 1) {
      this.numberTickets--
    }
  }

  selectPriceCategory(e) {
    console.log(e);
    this.selectedPriceCategory = this.priceCategories.find(x => x.identifier == e);
  }

  routeToUpgrade() {
    this.router.navigate(['/account/upgrade'], { queryParams: { returnUrl: this.router.url, creditsRequired: this.creditsRequired }});
  }

  routeToPersons() {
    this.bookingStep = 1;
  }

  routeToSeats() {

    if (this.priceCategories.length == 1 && this.eventType == "GENERAL_ACCESS") {
      this.bookingStep = 3;
      this.reserveEvent();
    } else {
      this.bookingStep = 2;
    }

  }

  // routeToConfirmation() {
  //   if(this.eventType != "GENERAL_ACCESS" && this.selectedSeats.length < this.numberTickets) {
  //     // SNACKBAR MIT HINWEIS AUF FEHLENDEN SITZ EINBAUEN
  //     return false
  //   } else {
  //     console.log("BOOK SEATED EVENT");
  //   }

  //   if(this.eventType != "GENERAL_ACCESS" && this.selectedPriceCategory.length < this.numberTickets) {

  //   } else {

  //   }

  // }

  selectSeat(seats:any) {
    console.log(seats);
    this.selectedSeats = seats;    
  }


  reserveEvent() {
    this.loading = true;
    const seatIds = [];

    var chosenAvailabilities = [];

    if(this.eventType == "GENERAL_ACCESS") {
 
      for (let i = 0; i < this.numberTickets; i++) {
        chosenAvailabilities.push({
          priceCategory: this.selectedPriceCategory.identifier
        });
      }     
    } else {
      for (let i = 0; i < this.numberTickets; i++) {
       if(this.selectedSeats[i]){        
          if (this.selectedSeats[i]?.seat?.id) {
            chosenAvailabilities.push({
              seatId: this.selectedSeats[i]?.seat.id,
              sectionId: this.selectedSeats[i]?.sectorId
            });
          } else {
            chosenAvailabilities.push({
              sectionId: this.selectedSeats[i]?.sectionId
            });
          }
        } else {
          this.loading = false;
          return
        }

      }
    }




    console.log(chosenAvailabilities);

    this.eventService.reserveEvent(this.eventId, this.showtimeId, chosenAvailabilities).subscribe(
      result => {
      if(result) {
        this.shippingMethod = result.shipping.method;
        this.price = result.price.amount;
        this.session = result.session;
        this.signature = result.signature;
        this.checkAccountBalance();
      }
    }, error => {
      console.log(error.details.errorCode);
      this.selectedSeats = [];
      this.bookingStep = 1;
      switch (error.details.errorCode) {

        case "TooLateReservationForShipping":
          this.errorMessage = "Tickets mit Post-Versand können nur bis 24h vorher gebucht werden.";
          console.log(this.errorMessage);
          break;

        case "ServerApplicationError":
            this.errorMessage = "Es ist etwas schief gelaufen. Bitte versuche es noch einmal.";
            console.log(this.errorMessage);
            break;
          
        default:
          this.errorMessage = "Event ist momentan leider nicht buchbar.";
          break;
      } 
      this.loading = false;

      
    });
  }

  checkoutEvent() {
    this.loading = true;
    this.eventService.checkoutEvent(this.session,this.signature).subscribe(result => {
      if(result) {
        this.router.navigate(['/booking/confirmation']);
      }
    }, error => {
      console.log(error);
      this.loading = false;
      this.errorMessage = "Buchung nicht möglich";
    });

  }

  ngOnInit(): void {
    this.getEventDetails();
    this.getUser();
  }

}