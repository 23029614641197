import { Component, Input, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-event-tabs',
  templateUrl: './event-tabs.component.html',
  styleUrls: ['./event-tabs.component.scss']
})
export class EventTabsComponent implements OnInit {

  @Input() headline: string;
  @Input() events: Array<any>;
  showtimes: Array<any>;

  constructor(    
    private eventService: EventService
    ) { }

  ngOnInit(): void {
  }

  getShowtimes(tabChangeEvent: MatTabChangeEvent){
    this.eventService.getEventShowtimes(this.events[tabChangeEvent.index].slug).subscribe(result => {
      this.showtimes = result;
      console.log(result);
    });
  }

}
