import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocationService } from 'src/app/services/location.service';
import { EventService } from 'src/app/services/event.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {

  locationUrl = this.router.url.split('?')[0].substring(this.router.url.lastIndexOf('/') + 1);
  location;
  locationId;
  events;

  constructor(    
    private router: Router,
    private eventService: EventService,
    private locationService: LocationService,
    private titleService: Title
    ) { }

  ngOnInit() {
    this.getLocation(this.locationUrl);  
  }

  getLocation(location) {
    this.locationService.getLocationBySlug(location).subscribe((result:any) => {
      this.titleService.setTitle(result[0]?.data?.venue + " · My Event Pass");
      this.location = result[0].data;
      this.locationId = result[0].id;
      this.getEvents(result[0].id);
    });
  }

  getEvents(locationId:string) {
    this.eventService.getEventsByLocation(locationId).subscribe(result => {
      this.events = result;
    });
  }

  // getShowtimes(collection, eventId) {
  //   this.eventService.getEventShowtimes(collection, eventId).subscribe(result => {
  //     this.showtimes = result;
  //     console.log(result);
  //   });
  // }

}
