import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CheckoutService } from 'src/app/services/checkout.service';

@Component({
  selector: 'app-pause',
  templateUrl: './pause.component.html',
  styleUrls: ['./pause.component.scss']
})
export class PauseComponent implements OnInit {

  loading:boolean = false;
  errorMessage:string;

  constructor(    
    private checkoutService: CheckoutService,
    private router: Router
    ) { }

  pauseSubscription() {
    this.loading = true;
    this.checkoutService.pauseSubscription().then(result => {
      this.router.navigate(["/account"],{ queryParams: { tab: 'subscription' }});
    }, error => {
      console.log(error.details.message);
      if (error.details.message = "Subscription can be paused only once per year") {
        this.errorMessage = "Ein Abo darf nur einmal pro Jahr pausiert werden.";
      } else {
        this.errorMessage = "Es ist ein Fehler aufgetreten, bitte wende dich an unseren Kundenservice.";
      }
      this.loading= false;
    });
  }

  ngOnInit(): void {
  }

}
