import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-email-check',
  templateUrl: './email-check.component.html',
  styleUrls: ['./email-check.component.scss']
})
export class EmailCheckComponent implements OnInit {

  loading:boolean = false;
  emailSend:boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { 
    this.checkVerifcationStatus();
  }

  ngOnInit(): void {
    this.checkVerifcationStatus();
  }

  resendEmailVerification():void {
    this.loading = true;
    this.authService.resendEmailVerification().then(result => {
      if (result) {
        this.loading = false;
        this.emailSend = true;
      }
    });
  }

  checkVerifcationStatus() {
    this.authService.getUserAuthData().then(result => {
      if(result?.emailVerified) {
        this.router.navigate(["/subscription/personal-data"]);
      }
    });
  }

}
