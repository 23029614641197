import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwiperOptions } from 'swiper';
import { getStorage, ref, getDownloadURL } from "firebase/storage";

@Component({
  selector: 'app-booking-detail',
  templateUrl: './booking-detail.component.html',
  styleUrls: ['./booking-detail.component.scss']
})
export class BookingDetailComponent implements OnInit {
  
  config: SwiperOptions;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.config = {
      slidesPerView: 1,
      spaceBetween: 20,
      navigation: false,
      pagination: true,
    };
    console.log(this.data);
  }

  getFileDownloadUrl(path:string) {
    const storage = getStorage();
    var windowReference = window.open();
    getDownloadURL(ref(storage, path))
      .then((url) => {
        windowReference.location = url;
      })
      .catch((error) => {
          console.log(error);
      });
  }


}