import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { ActionboxShowtimesComponent } from 'src/app/components/general/actionbox/actionbox-showtimes/actionbox-showtimes.component';
import { AuthService } from 'src/app/services/auth.service';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
  
  event: any;
  toggle: boolean = false;
  showtimes: Array<any>;
  toggleGallery: boolean = false;
  category: string = this.activatedRoute.snapshot.url[0].path;
  description;
  selectedShowtime: string;
  specialPrice:any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private eventService: EventService,
    private titleService: Title,
    public authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {      
    this.selectedShowtime = this.activatedRoute.snapshot.queryParamMap.get('showtime')||"";;
    this.getEvent();

  }

  getEvent() {
    const eventId = this.activatedRoute.snapshot.paramMap.get('id');
    this.eventService.getEvent(this.category, eventId).pipe(
      first()
    ).subscribe(result => {
      if(result.data) {
        this.titleService.setTitle(result.data.name + " · My Event Pass");
        this.event = result;
        this.getShowtimes(eventId);
        // this.getRecommendations(result.tmdb_id);
      } else {
        this.router.navigate([ "/start"]);
      }
    });
  }

  getShowtimes(eventId) {
    this.eventService.getEventShowtimes(eventId).subscribe((result:any) => {
      this.showtimes = result;
      // let arraytest= [];
      // result.forEach(element => {
      //   arraytest.push(element.data.eventName);
      // });
      const priceCategoryWithSpecialPrice = result[0].data.priceCategories.find(item => item.specialPrice);
      if (priceCategoryWithSpecialPrice) {
        this.specialPrice = priceCategoryWithSpecialPrice.specialPrice;
      }
      if(this.event?.data?.showtimesType === "priceCategoriesAsShowtimes") {
        this.eventService.updateShowtimesAvailability(eventId,this.showtimes[0].id);          
      }
    });
  }

}
