import { Component, OnInit } from '@angular/core';
import { PersonService } from 'src/app/services/person.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Person } from 'src/app/models/person';
import { Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss']
})
export class PersonComponent implements OnInit {

  personId: string = this.router.url.split('?')[0].substring(this.router.url.lastIndexOf('/') + 1);
  category: string = this.activatedRoute.snapshot.url[0].path;
  person: Person; 
  relatedEvents: object; 


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private personService: PersonService,
    private titleService: Title,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.getPerson(this.personId);
    this.getRelatedEvents(this.personId);  
  }

  getPerson(id:string) {
    this.personService.getPerson(id).subscribe(result => {
      this.titleService.setTitle(result?.name + " · My Event Pass");
      this.person = result;
    });
  }

  getRelatedEvents(id:string) {
    this.personService.getReleatedEvents(id).subscribe(result => {
      console.log(result);
      this.relatedEvents = result;
    });
  }

}
