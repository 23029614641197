import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent {

  @Input() headline: string;
  @Input() events: Array<any>;
  @Input() colorState: string;

  constructor() { }

  getPlaceholder(event, type) {
    event.target.src = "./assets/placeholder_"+type+".png";
  }

}
