import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CheckoutService } from 'src/app/services/checkout.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  balance: number = 0;
  initialAmount: number = 0;
  userAuthData$;
  orderNr:string = sessionStorage.getItem("orderNr");
  orderAmount:string = sessionStorage.getItem("orderAmount");



  constructor(   
    private authService: AuthService,
    private checkoutService: CheckoutService,
    private elementRef:ElementRef
  ) { }

  async ngOnInit(): Promise<void> {
    this.userAuthData$ = await this.authService.getUserAuthData();
    this.getAccountBalance();
    localStorage.removeItem('couponcode');
  }

  // ngAfterViewInit() {
  //   var s = document.createElement("script");
  //   s.type = "text/javascript";
  //   s.src = "https://widgets.trustedshops.com/js/XEC6BBD013906218857FBDC4763B7D908.js";
  //   this.elementRef.nativeElement.appendChild(s);
  // }

  async getAccountBalance() {
    var user$ = await this.authService.getUser();
    user$.subscribe((user) => {
      this.checkoutService.getSubscriptionPlans().subscribe(result => {  
        if(result) {
          let userSubscriptionPlan = user.tempSelectedProduct;
          let localStorageSubscriptionPlan = localStorage.getItem('selectedSubscriptionPlanId')
          if(localStorageSubscriptionPlan) {
            this.balance = result.data[localStorageSubscriptionPlan].prices[localStorageSubscriptionPlan + "-EUR-Monthly"].coinsAmount;
            this.initialAmount = result.data[localStorageSubscriptionPlan].prices[localStorageSubscriptionPlan + "-EUR-Monthly"].coinsAmount;
          } else {
            this.balance = result.data[userSubscriptionPlan].prices[userSubscriptionPlan + "-EUR-Monthly"].coinsAmount;
            this.initialAmount = result.data[userSubscriptionPlan].prices[userSubscriptionPlan + "-EUR-Monthly"].coinsAmount;
          } 
        }});
    });
  }

}