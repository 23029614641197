import { Component, OnInit } from '@angular/core';
import { MusicEvent } from 'src/app/models/musicevent';
import { EventService } from 'src/app/services/event.service';
import { PersonService } from 'src/app/services/person.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { LocationService } from 'src/app/services/location.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-music',
  templateUrl: './music.component.html',
  styleUrls: ['./music.component.scss']
})
export class MusicComponent implements OnInit {

  musicRecommendations: MusicEvent[] = [];
  musicUpcomming: MusicEvent[] = [];
  musicPopular: MusicEvent[] = [];

  musicClassic: MusicEvent[] = [];
  musicRock: MusicEvent[] = [];
  musicJazz: MusicEvent[] = [];
  musicPop: MusicEvent[] = [];
  musicKids: MusicEvent[] = [];
  musicHipHop: MusicEvent[] = [];

  musicMusical: MusicEvent[] = [];
  musicFestivals: MusicEvent[] = [];
  musicAll: any[] = [];
  musicToday: any[] = [];
  musicOthers: any[] = [];
  genres: Array<any> = [];
  category: string = this.activatedRoute.snapshot.url[0].path;
  topLocations: Array<any> = [];
  eventsWithSpecialPrice: Array<any> = [];
  config: SwiperOptions;

  constructor(    
    private eventService: EventService,    
    private activatedRoute: ActivatedRoute,
    private personService: PersonService,
    private http:HttpClient,
    private locationService: LocationService

    ) { }

  ngOnInit(): void {
    this.getMusicPopular();
    this.getMusicRock();
    this.getMusicGenres();
    this.getMusicClassic();
    this.getMusicJazz();
    this.getMusicPop();
    this.getMusicMusical();
    this.getNextMusic();
    this.getMusicHipHop();
    this.getMusicOthers();
    this.getTopLocations();
    this.getEventsWithSpecialPrice();

    let slidesPerViewXS = 1;
    let slidesPerViewMD = 2;
    let slidesPerViewLG = 3;

    this.config = {
      // slidesPerView: this.slides,

      breakpoints: {
        // when window width is >= 320px
        320: {
         slidesPerView: slidesPerViewXS,
         spaceBetween: 20
        },
        // when window width is >= 480px
        480: {
         slidesPerView: slidesPerViewMD,
         spaceBetween: 30
        },
        // when window width is >= 640px
        640: {
         slidesPerView: slidesPerViewLG,
         spaceBetween: 20
        }
      },

      spaceBetween: 20,
      navigation: true,
      mousewheel: {
        forceToAxis: true,
      },
      freeMode: true
    };

  }

  getMusicPopular() {
    this.eventService.getMusicPopular().subscribe((result: any) => {
      this.musicPopular = result;
    });
  }

  // getMusicElectro() {
  //   this.eventService.getEventsByGenre("electronic-dance").subscribe(result => {
  //     this.musicElectro = result;
  //   });
  // }

  getMusicRock() {
    this.eventService.getEventsByGenre("rock-pop", "mostRecentShowtime", 25).subscribe(result => {
      this.musicRock = result;
    });
  }

  getMusicClassic() {
    this.eventService.getEventsByGenre("klassik", "mostRecentShowtime").subscribe(result => {
      this.musicClassic = result;
    });
  }

  getMusicJazz() {
    this.eventService.getEventsByGenre("jazz", "mostRecentShowtime").subscribe(result => {
      this.musicJazz = result;
    });
  }

  getMusicPop() {
    this.eventService.getEventsByGenre("pop", "mostRecentShowtime").subscribe(result => {
      this.musicPop = result;
    });
  }

  getMusicHipHop() {
    this.eventService.getEventsByGenre("hip-hop", "mostRecentShowtime").subscribe(result => {
      this.musicHipHop = result;
    });
  }

  

  getMusicMusical() {
    this.eventService.getEventsByGenre("musical-show").subscribe(result => {
      this.musicMusical = result;
    });
  }

  getMusicGenres() {
    this.eventService.getGenres("music").subscribe((result: any) => {
      this.genres = result;
    });
  }

  getMusicAll() {
    this.eventService.getMusicEvents("music").subscribe((result: MusicEvent[]) => {
      this.musicAll = result;
    });
  }

  getTopLocations() {
    this.locationService.getTopLocationsByType("music").then((result:any) => {
      if(result.docs) {
        this.topLocations = result.docs;
      }
    });
  }

  // getMusicToday() {
  //   this.eventService.getMusicToday().subscribe((result: any) => {
  //     this.musicToday = result;      
  //   });
  // }

  getNextMusic() {
    this.eventService.getNextMusic().subscribe((result: any) => {
      this.musicUpcomming = result;
      let today = new Date();
      today.setHours(23,59,0,0);
      result.forEach(element => {
        if(new Date(element.data.datetime) < today) {
          this.musicToday.push(element);
        }
      });      
    });
  }

  getEventsWithSpecialPrice() {
    this.eventService.getEventsWithSpecialPrice().subscribe((result) => {
      console.log(result);
      this.eventsWithSpecialPrice = result;
    });
  }

  getMusicOthers() {
    this.eventService.getEventsWithoutGenre("music", "mostRecentShowtime",20).subscribe((result: any) => {
      this.musicOthers = result;
    });
  }


  transferShowtimesHelper() {
    console.log("s");
  }
  

}

