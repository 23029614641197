import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
// import * as L from 'leaflet';
// import * as mapboxgl from 'mapbox-gl';
import { LocationService } from 'src/app/services/location.service';
import { EventService } from 'src/app/services/event.service';
import mapboxgl from 'mapbox-gl';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit {

  private map: mapboxgl.Map;
  scaledMap: boolean = false;
  locations: Array<any> = [];
  @Input() eventsToday: Array<any>;
  @Input() type: string;

  constructor(  
    public authService: AuthService,  
    private locationService: LocationService,
    private eventService: EventService,
    private cd: ChangeDetectorRef
    ) { }




    
  private initMap(res:any):void {

    if (this.authService.user$) {
      // Init map
      this.map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        zoom: 12,
        center: [11.57898,48.13495],
        accessToken: environment.mapbox.accessToken,
      });
    }

    const ro = new (window as any).ResizeObserver(() => {
      if (this.map) {
          this.map.resize();
      }
    });
  
    ro.observe(document.getElementById('map'));














    // Add map controls
    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.addControl(new mapboxgl.FullscreenControl());

    // Set Markers

    



      var bounds = new mapboxgl.LngLatBounds();          
      var locationExport:Array<any> = [];
      for (let i = 0; i < res.docs.length; i++) {   

        if(res.docs[i].data().geolocation?.long && res.docs[i].data().geolocation?.long > 0 && res.docs[i].data().type !== 'unknown') {
          let location:mapboxgl.LngLatLike = [res.docs[i].data().geolocation.long,res.docs[i].data().geolocation.lat];
          let locationEvents = [];
          let type = res.docs[i].data().type;
          const el = document.createElement('div');
          el.className = 'locationMarker';
          el.style.backgroundImage = 'url(../../../assets/icons/'+type+'.svg)';

          if(res.docs[i].data().eventCount == 0) {
            el.style.opacity = '0.5';
          }

          let todaysShowtime =  this.checkIfShowtimeToday(res.docs[i].data().slug);
          if (todaysShowtime)  {
            el.style.border = '3px solid rgb(245, 40, 80)';
          }




          var tempMarker = new mapboxgl.Marker(el)
          .setLngLat(location)
          .setPopup(new mapboxgl.Popup({maxWidth:'none',anchor:'top'})
            .on('close', () => {
              this.scaledMap = false;
            })
            .on('open', () => {
              this.scaledMap = true;
              // this.eventService.getEventsByLocation(res.docs[i].data().slug).subscribe(result => {
              //   console.log(res.docs[i].data());
              //   if(result) {
              //     console.log(result);
              //     // locationEvents = result;
              //   }
              // });
            })
            .setHTML( 
              "<h2>"+ 
              res.docs[i].data().venue +
              "</h2><p><small>"+ 
              res.docs[i].data().street +
              ", "+ res.docs[i].data().city +
              "</small></p><app-event-list [events]="+
           
              
              locationEvents +
              "></app-event-list><a class='button primary full-width' href='/location/"+ 
              res.docs[i].data().slug +
              "'>Events ("+res.docs[i].data().eventCount+") anzeigen</a>"
              ))
            .addTo(this.map)

          tempMarker.getElement().addEventListener('click', () => {
            this.cd.detectChanges();
            this.map.resize();
            this.map.flyTo({
              center: location,
              zoom: 15
              });
          });

          // tempMarker.togglePopup();
         
          bounds.extend(location);
          


        }
      }   
      // this.map.fitBounds(bounds,{ padding: {top:50,left:50,right:50,bottom:300} });



  }


  checkIfShowtimeToday(locationSlug:string) {
    let todaysShowtime = this.eventsToday.find(x => x.data.location.slug == locationSlug);
    if(todaysShowtime) {return true}
    return false
  }

  ngAfterViewInit(): void {
    if (this.authService.user$) {
      this.locationService.getAllLocations(this.type).then((res) => {
        if(res) {
          this.locations = res;
          this.initMap(res);  
        }
      });
    }    
  }

}
