import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-others',
  templateUrl: './others.component.html',
  styleUrls: ['./others.component.scss']
})
export class OthersComponent implements OnInit {
  
  category: string;
  musicOthers: Array<any> = [];
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private eventService: EventService
  ) { 
    this.category = this.activatedRoute.snapshot.url[0].path;
  }

  getMusicOthers() {
    this.eventService.getEventsWithoutGenre("music", "mostRecentShowtime").subscribe((result: any) => {
      this.musicOthers = result;
    });
  }


  ngOnInit(): void {
    this.getMusicOthers();
  }

}
