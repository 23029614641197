import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-subpage-layout',
  templateUrl: './subpage-layout.component.html',
  styleUrls: ['./subpage-layout.component.scss']
})
export class SubpageLayoutComponent implements OnInit {

  userAuthData$;

  constructor(    
    private _location: Location,
    public authService: AuthService,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    this.userAuthData$ = await this.authService.getUserAuthData();
  }

  backLink() {
    var parts = document.referrer.split('://')[1].split('/');    
    if (document.referrer == "") {
      this.router.navigate(['/start']);
    } else if (parts[3] === "booking") {
      this.router.navigate(['/'+parts[1]]);
    } else  { 
      this._location.back();
    }
  }

}
