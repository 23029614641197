import { Component, OnInit, Inject } from '@angular/core';
import { EventService } from 'src/app/services/event.service';
import { City } from 'src/app/models/city';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { GeoPoint } from '@google-cloud/firestore';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  selectedCityName:string = this.userService.getCurrentCity();

  constructor(
    private eventService: EventService,
    private _bottomSheet: MatBottomSheet,
    private userService: UserService
  ) { }

  openCitySelector(): void {
    const bottomSheetRef = this._bottomSheet.open(CitySelector);
    // this.eventService.getCities().subscribe((result:[City]) => {
    //   if(result) {
    //     const bottomSheetRef = this._bottomSheet.open(CitySelector, { data: result });
    //     bottomSheetRef.afterDismissed().subscribe((data) => {
    //       if(data != undefined) {
    //         this.selectedCityName = data;
    //         this.userService.setCurrentCity(data);
    //       }
    //     });
    //   }
    // });
  }

  ngOnInit(): void {
    if (!this.selectedCityName || this.selectedCityName == "undefined") {
      this.selectedCityName = "München";
      this.userService.setCurrentCity("München");
    }
  }


}

@Component({
  selector: 'city-selector',
  templateUrl: 'city-selector.html',
})
export class CitySelector {


  constructor(
    // @Inject(MAT_BOTTOM_SHEET_DATA) public data: Array<City>,
    private _bottomSheetRef: MatBottomSheetRef<CitySelector>
  ) {}

  dismiss() {
    this._bottomSheetRef.dismiss();
  }

  switchCity(city:string): void {
    this._bottomSheetRef.dismiss(city);
    event.preventDefault();
  }
}