import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { EventService } from 'src/app/services/event.service';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-genre',
  templateUrl: './genre.component.html',
  styleUrls: ['./genre.component.scss']
})
export class GenreComponent implements OnInit {

  genre;
  genres: Array<any> = [];
  genreSlug: string = this.router.url.split('?')[0].substring(this.router.url.lastIndexOf('/') + 1);
  events;
  type: string;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    location.reload()
  }
  
  constructor(
    private eventService: EventService,
    private router: Router,
    private activatedRoute: ActivatedRoute,    
    private _bottomSheet: MatBottomSheet,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.getEventsByGenre();
    this.getGenre(this.genreSlug);
    this.type = this.activatedRoute.snapshot.url[0].path;
    if (this.genres != null) {
      this.eventService.getGenres(this.type).subscribe((result: any) => {
        if (result) {
          this.genres = result;
        }
      });  
    }
  }

  getGenre(slug:string) {
    this.eventService.getGenre(slug).subscribe(result => {
      this.titleService.setTitle(result?.name + " · My Event Pass");
      this.genre = result;
    });
  }

  getEventsByGenre() {
    this.eventService.getEventsByGenre(this.genreSlug, "mostRecentShowtime").subscribe(result => {
      this.events = result;
    });
  }

  openGenreSelector(): void {
    if (this.genres != null) {
      this.eventService.getGenres(this.type).subscribe((result: any) => {
        if (result) {
          this.genres = result;
          
          this._bottomSheet.open(GenreSelectorBottomSheet, {
            data: { genres: this.genres },
          });
        }
      });  
    } else {
      this._bottomSheet.open(GenreSelectorBottomSheet, {
        data: { genres: this.genres },
      });

    }

  }

  openLink(genre: any) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>  this.router.navigate(['/' + genre.data.type + '/genre/' + genre.data.slug]));
  }

}



@Component({
  selector: 'genre-selector',
  templateUrl: 'genre-selector.html',
})
export class GenreSelectorBottomSheet {
  constructor(
    private router: Router,
    private _bottomSheetRef: MatBottomSheetRef<GenreSelectorBottomSheet>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {}

  ngOnInit(): void {
    console.log(this.data);
  }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
  }

}