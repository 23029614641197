import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Person } from '../models/person';

@Injectable({
  providedIn: 'root'
})
export class PersonService {

  constructor(
    private db: AngularFirestore 
  ) { }

  getPerson(document:string): Observable<Person> {
    return this.db.collection('persons').doc(document)      
    .snapshotChanges().pipe(map(action => {
      return action.payload.data() as Person;
    }));
  }


  // TODO: DATA TYPE SHOULD BE SWITCHED TO EVENT MODEL 
  getReleatedEvents(document:string): Observable<any> {
    return this.db.collection('persons').doc(document).collection('events')      
    .snapshotChanges()
    .pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data(); 
        const id = a.payload.doc.id;    
        return { id, data };      }))
    );
  }
  
  getPersonsPopular(type:string) {
    // return this.db.collection('persons', ref => ref.where("type","==",type).orderBy('popularity','desc').limit(10))
    return this.db.collection('persons', ref => ref.orderBy('popularity','desc').limit(20))
    .snapshotChanges()
    .pipe(
      map(actions => actions.map(a => {
        return a.payload.doc.data();        
      }))
    );
  }


  getTopPersons() {
    return new Promise<any>((resolve, reject) => {
      this.db
        .collection("persons", ref => ref.where("visible","==",true).orderBy('popularity','desc').limit(20))
        .get()
        .subscribe(res => resolve(res), err => reject(err));
    });
  }
 
}
