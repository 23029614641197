import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { Router } from '@angular/router';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-reactivate',
  templateUrl: './reactivate.component.html',
  styleUrls: ['./reactivate.component.scss']
})
export class ReactivateComponent implements OnInit {

  user$;
  subscription;
  loading: boolean = true;
  newSubscription; 

  constructor(
    public authService: AuthService,
    private checkoutService: CheckoutService,
    private router: Router,
    private analytics: AngularFireAnalytics,
  ) { }

  async getUserSubscription() {
    this.user$ = await this.authService.getUser();
    this.user$.subscribe((user) => {
      this.subscription = user?.subscription;
      this.loading = false;
      console.log(this.subscription);
      this.getNewSubscriptionPlan(this.subscription.plan);
    });
  }

  getNewSubscriptionPlan(planId:string) {
    this.checkoutService.getSubscriptionPlans().subscribe(result => {
      if(result) {
        this.newSubscription = result.data[planId];
        console.log(this.newSubscription);
      }
    });
  }

  reactivateSubscription() {
    this.loading = true;
    this.checkoutService.reactivateSubscription().then(result => {
      this.analytics.logEvent('subscription_renewed');
    });
    this.router.navigate(["/account"]);
  }

  ngOnInit(): void {
    this.getUserSubscription();
  }

}
