import { Component, OnInit } from '@angular/core';
import { MatChip } from '@angular/material/chips';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-intro-movie',
  templateUrl: './intro-movie.component.html',
  styleUrls: ['./intro-movie.component.scss']
})
export class IntroMovieComponent implements OnInit {

  genres: Array<any> = [];

  constructor(    
    private eventService: EventService
    ) { }

  ngOnInit(): void {
    this.getMovieGenres();
  }

  getMovieGenres() {
    this.eventService.getGenres("movie").subscribe((result: any) => {
      this.genres = result;
    });
  }

  toggleSelection(chip: MatChip) {
    chip.toggleSelected();
  }

}
