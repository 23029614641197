import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-booking-logout-preview',
  templateUrl: './booking-logout-preview.component.html',
  styleUrls: ['./booking-logout-preview.component.scss']
})
export class BookingLogoutPreviewComponent implements OnInit {

  // @Input() authenticated:boolean = false;

  loading:boolean = true;
  eventId:string;
  eventType:string;
  event;
  showtimeId:string;
  showtime;
  seatingPlan:any;
  priceCategories: Array<any> = [];
  selectedPriceCategory: any;
  errorMessage: string;
  numberTickets: number = 1;
  selectedSeats: Array<any> = [];
  userAuthData$

  constructor(    
    private eventService: EventService,
    private route: ActivatedRoute,
    public authService: AuthService
  ) {
    this.route.paramMap.subscribe( paramMap => {
      this.eventId = paramMap.get('id');
      this.showtimeId = paramMap.get('showtimeid');
    })
  }

  async ngOnInit(): Promise<void> {
    this.getEventDetails();
    this.getPriceCategories(this.eventId, this.showtimeId);
    this.userAuthData$ = await this.authService.getUserAuthData();
  }

  async getPriceCategories(eventId:string, showtimeId:string) {
    this.eventService.getPriceCategories(eventId, showtimeId).then(result => {
      this.eventType = result.type;
      if (result.priceCategories.length > 0) {
        this.seatingPlan = result.seatingPlan;
        this.priceCategories = result.priceCategories;
      } else {
        this.errorMessage = "Das Event ist zu dieser Uhrzeit leider ausgebucht.";
      }
      this.loading = false;
      console.log(this.loading);
    });
  }

  getEventDetails(): void {
    this.eventService.getEvent("movie", this.eventId).subscribe((result:any) => {
      this.event = result.data;
      this.getShowtimeDetails();
    });
  }

  getShowtimeDetails(): void {
    this.eventService.getEventShowtimeById(this.eventId, this.showtimeId).subscribe((result:any) => {
      this.showtime = result.data;
      this.getPriceCategories(this.eventId, this.showtimeId);
    });
  }

  selectSeat(seats:any) {
    console.log(seats);
    this.selectedPriceCategory = this.priceCategories.find(x => x.identifier == seats[0]?.seat?.priceCategoryId);
    if (!this.selectedPriceCategory) {
      this.selectedPriceCategory = this.priceCategories.find(x => x.id == seats[0]?.seat?.priceCategoryId);
    } 
    if (!this.selectedPriceCategory) {
      this.selectedPriceCategory = this.priceCategories[0];
    } 
    this.selectedSeats = seats;    
  }

  selectPriceCategory(e) {
    this.selectedPriceCategory = this.priceCategories.find(x => x.identifier == e);
  }


}
