import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventService } from 'src/app/services/event.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-today',
  templateUrl: './today.component.html',
  styleUrls: ['./today.component.scss']
})
export class TodayComponent implements OnInit {

  events: Array<any> = [];
  rawEvents: Array<any> = [];
  exhibitions: Array<any> = [];
  dateFilter: string = "morgen";
  locations: Array<any> = [];
  filterData;
  showExhibitions: boolean = true;

  constructor(
    private eventService: EventService,
    public datepipe: DatePipe,
    private activatedRoute: ActivatedRoute
  ) { 
    this.dateFilter = this.activatedRoute.snapshot.url[1].path;
  }

  getEventsByDate(minDate:any, maxDate:any) {
    this.eventService.getEventsByDate(minDate, maxDate).subscribe((result:any) => {
      this.rawEvents = result;
      if ((result.length > 0) && (localStorage.getItem("filter"))) {
        this.setFilter(JSON.parse(localStorage.getItem("filter")), result);
      } else {
        this.structureEvents(result);
      }
    });
  }
 
  getPlaceholder(event, type) {
    event.target.src = "./assets/placeholder_"+type+".png";
  }

  structureEvents(events:Array<any>) {
    this.events = [];
    console.log(events);
      for (let i = 0; i < events?.length; i++) {  
  

        if(events[i]?.data?.eventType !== 'unknown') {

        // SET SHOWTIME DAY
        var showtimeDay = new Date(events[i]?.data?.datetime);
        showtimeDay.setHours(0,0,0,0);
  
        // FILTER BY SHOWTIMEDAY
        let dayArray = this.events.find(x => x.day == showtimeDay.toString());
  
        // IF SHOWTIME DAY EXISTS
        if(dayArray) {
  
          // CHECK IF TIMESLOT ALREADY EXISTS
          let resultArr = dayArray.timeslots.find(obj => obj.datetime === events[i].data.datetime);
  
          if(resultArr) {
            if(events[i]?.data?.eventType == "exhibition") {

            } else {
            resultArr.showtimes.push({
              "eventName": events[i]?.data?.eventName,
              "eventImage": events[i]?.data?.eventImage,
              "cheapestPrice": this.findCheapestPrice(events[i]?.data?.priceCategories, events[i]?.data?.channel),
              "type": events[i]?.data?.eventType,
              "slug": events[i]?.id
            });
          }
          } else {
            if(events[i]?.data?.eventType == "exhibition") {

            } else {
            this.events.find(x => x.day == showtimeDay.toString()).timeslots.push({
              "datetime": events[i]?.data?.datetime,
              "showtimes": [{
                "eventName": events[i]?.data?.eventName,
                "eventImage": events[i]?.data?.eventImage,
                "cheapestPrice": this.findCheapestPrice(events[i]?.data?.priceCategories, events[i]?.data?.channel),
                "type": events[i]?.data?.eventType,
                "slug": events[i]?.id
              }]
            });}
          }
  
        // IF SHOWTIME DAY DOES NOT EXISTS
        } else { 

          if(events[i]?.data?.eventType == "exhibition") {

          } else {
            this.events.push({
              "day" : showtimeDay,
              "timeslots" : [{
                "datetime": events[i]?.data?.datetime,
                "showtimes": [{
                  "eventName": events[i]?.data?.eventName,
                  "eventImage": events[i]?.data?.eventImage,
                  "cheapestPrice": this.findCheapestPrice(events[i]?.data?.priceCategories, events[i]?.data?.channel),
                  "type": events[i]?.data?.eventType,
                  "slug": events[i]?.id
                }]
              }]
            });
          }

        }
  
      }   

      }
    }

  dateCheck(date) {
    let today = new Date();
    today.setHours(0,0,0,0);
    let tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    let showTime = new Date (date);
    // showTime.setHours(0,0,0,0);
    let showTimeCheck = new Date (showTime);
    showTimeCheck.setHours(0,0,0,0);
    if(showTimeCheck.toString() == today.toString()) {
      return "Heute";
    } else if (showTimeCheck.toString() == tomorrow.toString()) {
      return "Morgen";
    } else {
      return this.datepipe.transform(date, 'EEEE').toString();
    }
  }

  findCheapestPrice(priceCategories, channel) {
    let cheapestPricecategory;
    switch(channel) {
      case "cinetixx":
        cheapestPricecategory = priceCategories.find(obj => obj.category === "Web-Normal");
        return cheapestPricecategory?.coins
      default:
        let smallestItem = 10000;
        priceCategories.forEach(element => {
          console.log(element.coins);
          console.log(smallestItem);
          if (element.coins < smallestItem) {
            smallestItem = element.coins;
          }
        });
        return smallestItem
    }
  }

  getExhibitions() {     
    this.eventService.getExhibitions().subscribe((result: any) => {
      this.exhibitions = result;
    });
  }

  ngOnInit(): void {

    this.getExhibitions();

    let minDate;
    let maxDate;

    let now = new Date();

    let today = new Date(now);
    today.setHours(23,59,0,0);

    let tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);


    switch (this.dateFilter) {
      case "heute":
        minDate = now.toISOString();
        maxDate = new Date(today).toISOString();
        this.getEventsByDate(minDate,maxDate);
      break;

      case "morgen":
        let tomorrowMorning = new Date(tomorrow);
        tomorrowMorning.setHours(0,0,0,0);
        minDate = new Date(tomorrowMorning);
        maxDate = new Date(tomorrow);
        this.getEventsByDate(minDate.toISOString(),maxDate.toISOString());
      break;

      case "wochenende":
        let dayOfToday = now.getDay();
        if(dayOfToday == 6) {
          let startDate = now.toISOString();
          let endDate = new Date(today);
          endDate.setDate(today.getDate() + 1);
          endDate.setHours(23,59,0,0);
          this.getEventsByDate(startDate,endDate.toISOString());
        } else if(dayOfToday == 0) {
          minDate = now.toISOString();
          maxDate = new Date(today).toISOString();
          this.getEventsByDate(minDate,maxDate);
        } else {
          let daysToWeekend = 5 - dayOfToday;
          let startDate = new Date(now);
          startDate.setDate(now.getDate() + daysToWeekend);
          startDate.setHours(16,0,0,0);
          let endDate = new Date(startDate);
          endDate.setDate(startDate.getDate() + 2);
          endDate.setHours(23,59,0,0);
          this.getEventsByDate(startDate.toISOString(),endDate.toISOString());
        }
      break;

      default:
      break;
    } 

  }

  setFilter(event, allEvents) {
    if ((event == undefined) || !allEvents) {return}
    let categories = Object.keys(event.categories).filter(key => event.categories[key]);
    let filteredEvents = allEvents; 

    // CHECK IF MUSEUM FILTER IS ON
    if(categories.find(e => e === "exhibition" )) {
      this.showExhibitions = true;
    } else {
      this.showExhibitions = false;
    }

    // FILTER EVENTS BY CATEGORIES
    filteredEvents = filteredEvents.filter(element => {
      if (categories.find(e => e === element.data.eventType )) {
        return true
      }
      return false;
    });

    // FILTER EVENTS BY BALANCE BUDGET
    if(event.inBudget) {

      
      // {
      //   if (result.balance) {
       
        filteredEvents = filteredEvents.filter(element => {
          if (this.findCheapestPrice(element.data.priceCategories, element?.data?.channel) < event.budget) {
            return true;
          }
          return false;
        });
      //   }
      // });
    }
    this.structureEvents(filteredEvents);
  }

 

}

