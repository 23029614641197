import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { LocationService } from 'src/app/services/location.service';
import { AuthService } from 'src/app/services/auth.service';
import { EventService } from 'src/app/services/event.service';
import { UserService } from 'src/app/services/user.service';
import { CheckoutService } from 'src/app/services/checkout.service';

@Component({
  selector: 'app-booking-movie-seat',
  templateUrl: './booking-movie-seat.component.html',
  styleUrls: ['./booking-movie-seat.component.scss']
})
export class BookingMovieSeatComponent implements OnInit {

  user$;
  seatingPlan:any;
  eventId: string;
  event;
  showtime;
  showtimeId: string;
  loading:boolean = true;
  selectedSeats: Array<any> = [];
  selectedPriceCategory: any;
  // selectedSeatsData: Array<any> = [];
  price: number;
  session: string;
  signature: string;
  errorMessage: string;
  type: string;
  accountBalance;
  numberTickets: number = 1;
  priceCategories: Array<any> = [];
  eventToExpensive:boolean = false;
  seatSelectionView:boolean = false;
  creditsRequired;
  allowedticketsPerBooking: number = 1;
  tempSubscriptionPlan: any;
  shippingMethod:string;
  
  constructor(    
    private _location: Location,
    private locationService: LocationService,
    private route: ActivatedRoute,
    private eventService: EventService,    
    private router: Router,
    private userService: UserService,
    public authService: AuthService,
    private checkoutService: CheckoutService
    ) { 
      this.route.paramMap.subscribe( paramMap => {
        this.eventId = paramMap.get('id');
        this.showtimeId = paramMap.get('showtimeid');
      })
    }

  ngOnInit(): void {
    this.getSeats(this.eventId,this.showtimeId);
    this.getEventDetails();
    this.getShowtimeDetails();
    this.getSubscriptionPlans();
  }

  getSubscriptionPlans(): void {
    this.checkoutService.getSubscriptionPlans().subscribe((result:any) => {
      if(result) {
        this.getSubscriptionRules(result);
      }
    });
  }

  async getSubscriptionRules(subscriptionPlans): Promise<void> {
    this.user$ = await this.authService.getUser();
    this.user$.subscribe((user) => {
      if (user?.subscription?.plan) {
        this.tempSubscriptionPlan = subscriptionPlans.find(x => x.id == user?.subscription?.plan);
        this.allowedticketsPerBooking = this.tempSubscriptionPlan?.maxPersons;
      } else {
        this.router.navigate(['/account']);
      }
    });
  }


  getEventDetails(): void {
    this.eventService.getEvent("movie", this.eventId).subscribe((result:any) => {
      this.event = result.data;
      this.loading = false;
    });
  }

  getShowtimeDetails(): void {
    this.eventService.getEventShowtimeById(this.eventId, this.showtimeId).subscribe((result:any) => {
      this.showtime = result.data;
    });
  }

  backLink() {
    this._location.back();
  }

  upgradeLink() {
    this.router.navigate(['/account/upgrade'], { queryParams: { returnUrl: this.router.url, creditsRequired: this.creditsRequired }});
  }

  selectSeat(seats:any) {
    console.log(seats);
    this.selectedSeats = seats;    
  }

  saveSeats() {
    this.seatSelectionView = false;
  }

  addTicket() {
    if(this.numberTickets < this.allowedticketsPerBooking) {
      this.numberTickets++
    }
  }

  removeTicket() {
    if(this.numberTickets > 1) {
      this.numberTickets--
    }
  }

  checkAccountBalance() {
    this.userService.getAccountBalance().subscribe(result => {
      this.accountBalance = result.balance;
      if (this.price > result.balance) {
        this.eventToExpensive = true;
        this.creditsRequired = this.price - result.balance;
        this.loading = false;
      } else {
        this.eventToExpensive = false;
        this.loading = false;
      }
    });
  }




// ######################################
// EVENT BOOKING PROCESS
// ######################################

  async getSeats(eventId:string, showtimeId:string) {
    this.eventService.getPriceCategories(eventId, showtimeId).then(result => {
      this.type = result.type;
      console.log(result);      

      this.seatingPlan = result.seatingPlan;
      this.priceCategories = result.priceCategories;
      this.selectedPriceCategory = this.priceCategories[0];
      console.log(this.selectedPriceCategory);
      console.log(this.priceCategories);
    });
  }

  reserveEvent() {
    this.loading = true;
    const seatIds = [];

    var chosenAvailabilities = [];



    if(this.type == "GENERAL_ACCESS") {
 
      for (let i = 0; i < this.numberTickets; i++) {
        chosenAvailabilities.push({
          priceCategory: this.selectedPriceCategory.identifier
        });
      }     
    } else {
      for (let i = 0; i < this.numberTickets; i++) {
        chosenAvailabilities.push({
          seatId: this.selectedSeats[i]?.seat.id,
          // priceCategory: this.selectedPriceCategory, 
          sectionId: this.selectedSeats[i]?.sectorId
        });
      }
    }




    console.log(chosenAvailabilities);

    this.eventService.reserveEvent(this.eventId, this.showtimeId, chosenAvailabilities).subscribe(
      result => {
      if(result) {
        this.shippingMethod = result.shipping.method;
        console.log(result.shipping.method);
        this.price = result.price.amount;
        this.session = result.session;
        this.signature = result.signature;
        this.checkAccountBalance();
      }
    }, error => {
      console.log(error);
      this.selectedSeats = [];
      this.loading = false;
      // this.selectedSeatsData = [];
      if (error?.details?.reason) {
        this.errorMessage = error?.details?.reason;
      } else {
        this.errorMessage = "Event ist momentan leider nicht buchbar.";
      }
    });
  }

  selectPriceCategory(e) {
    this.selectedPriceCategory = this.priceCategories.find(x => x.identifier == e);
  }

  checkoutEvent() {
    this.loading = true;
    this.eventService.checkoutEvent(this.session,this.signature).subscribe(result => {
      if(result) {
        this.router.navigate(['/booking/confirmation']);
      }
    }, error => {
      console.log(error);
      this.loading = false;
      this.errorMessage = "Buchung nicht möglich";
    });

  }

}
