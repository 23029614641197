import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import algoliasearch from 'algoliasearch/lite';
import { EventService } from 'src/app/services/event.service';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';

const searchClient = algoliasearch(
  environment.algoliasearch.appId,
  environment.algoliasearch.apiKey
);

const newDate = new Date();
const today = newDate.getDate();
const month = newDate.getMonth();
const year = newDate.getFullYear();
const translationTable = {
  music: 'Konzert ',
  theater: 'Theater ',
  exhibition: 'Museum ',
  movie: 'Kino ',
  sport: 'Sport ',
  kinder: 'Kinder ',
};
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  searchParameters = {      
    // filters: 'mostRecentShowtime:1514764800 TO 1546300799',
    // filters: 'visible:true',
  };
  showFilter:boolean = false;
  config = {
    indexName: 'events',
    // hitsPerPage: '{2}',
    searchClient:searchClient,
    // filters: 'visible:true',
  };
  recommendations;
  readonly showtimeRange = new FormGroup({
    start: new FormControl(new Date(year, month, today)),
    end: new FormControl(new Date(year, month, today+7)),
  });

  constructor(    
    private _location: Location,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.getRecommendations();
  }

  getRecommendations(): void {
    this.eventService.getRecommendations().subscribe(results => {
      this.recommendations = results;
    });
  }

  changeDateFilter() {
    console.log(this.showtimeRange.value);
    // index.search(
    //   new Query("YourSearchQuery")
    //     .setFilters("date_timestamp:1514764800 TO 1546300799")
    // );
  }

  public convertToIcons(items) {
    console.log(items);
    return items.map(item => ({
      ...item,
      label: translationTable[item.label] || item.label,
      highlighted: translationTable[item.highlighted] || item.highlighted,
    }));
  }
 


  backLink() {
    this._location.back();
  }

}