import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { map, Observable, take } from 'rxjs';
import { CreatePaymentMethodCardData, PaymentIntent, PaymentIntentResult, PaymentMethod, PaymentMethodResult, Stripe, StripeCardElement, StripeError } from '@stripe/stripe-js';
import { HttpClient } from '@angular/common/http';
import { StripeService } from 'ngx-stripe';
import { SubscriptionAddon, SubscriptionPlan } from '../models/subscriptionplan';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import { initializeApp } from 'firebase/app';
import { environment } from './../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  app = initializeApp(environment.firebase);
  functions = getFunctions(this.app,"europe-west1");

  constructor(    
    private fns: AngularFireFunctions,
    private http: HttpClient,
    private stripeService: StripeService,
    private db: AngularFirestore
    ) { }

  // getSubscriptionPlans2 (): Observable<Array<SubscriptionPlan>>{
  //   const getSubscriptionPlans = this.fns.httpsCallable('getSubscriptionPlans');
  //   return getSubscriptionPlans({}).pipe(    
  //     map(actions => actions.map(a => {
  //       const id = a.id;
  //       const name = a.name;
  //       const prices = a.metadata.prices;
  //       const description = a.description;
  //       const status = a.status;
  //       const maxPersons = a.maxPersons;
  //       const coinsAmount = a.coinsAmount;
  //       return {a,id,name,prices,description,status,maxPersons,coinsAmount};
  //     }))
  //   );
  // }

  getSubscriptionPlans(): Observable<any> {
    return this.db.collection("configuration").doc("subscriptions")      
    .snapshotChanges().pipe(map(action => {
      const data = action.payload.data(); 
      return { data };   
    }));
  }

  // getSubscriptionAddons (): Observable<Array<SubscriptionPlan>>{
  //   const getSubscriptionAddons = this.fns.httpsCallable('getSubscriptionAddons');
  //   return getSubscriptionAddons({}).pipe(
  //     map(actions => actions.map(a => {
  //       const id = a.id;
  //       const name = a.name;
  //       const prices = a.metadata.prices;
  //       const description = a.description;
  //       const status = a.status;
  //       return {id,name,prices,description,status};
  //     }))
  //   );
  // }

  // getExtraCoinsPackages (): Observable<Array<any>>{
  //   const getExtraCoinsPackages = this.fns.httpsCallable('getExtraCoinsPackages');
  //   return getExtraCoinsPackages({}).pipe(
  //     map(actions => actions.map(a => {
  //       const id = a.id;        
  //       const name = a.name;
  //       const coinsAmount = a.metadata.prices[a.id + "-EUR"].coinsAmount;
  //       const price = a.metadata.prices[a.id + "-EUR"].price / 100;
  //       return {id, name, coinsAmount,price};
  //     }))
  //   );
  // }


  getExtraCoinsPackages(): Observable<any> {
    return this.db.collection("configuration").doc("additionalPackages")      
    .snapshotChanges().pipe(
      map(action => {
      const data = action.payload.data();
      console.log(data); 
      return { data };   
    }));
  }

  getSubscriptionCoins(subscriptionPlanId:string): Observable<any> {
    return this.db.collection("configuration").doc("coin-amount")      
    .snapshotChanges().pipe(map(action => {
      const data = action.payload.data(); 
      return { data };   
    })); 
  }






  async updateCustomer(formRawValue): Promise<any> {
    const updateCustomer = this.fns.httpsCallable('updateCustomer');
    return await updateCustomer(formRawValue).toPromise();
  }
  
  // updateCustomer (formRawValue): Observable<any>{
  //   const updateCustomer = this.fns.httpsCallable('updateCustomer');
  //   return updateCustomer(formRawValue);
  // }

  // updateCustomer(formRawValue): Promise<any> {
  //   const updateCustomerData = httpsCallable(this.functions, 'updateCustomer');
  //   return updateCustomerData(formRawValue);
  // }


  getCoupon(couponCode:string): Promise<any> {
    const getCoupon = httpsCallable(this.functions, 'getCoupon');
    return getCoupon({"coupon":couponCode});
  }



  // SUBSCRIPTION VIA CREDIT CARD
  // #############################################################

  createCardPaymentMethod(paymentMethodCardData:CreatePaymentMethodCardData): Observable<{ paymentMethod?: PaymentMethod; error?: StripeError; }> {
    return this.stripeService.createPaymentMethod(paymentMethodCardData);
  }

  createCardPaymentIntent(subscriptionPriceId: string, paymentMethodId: string): Observable<PaymentIntent> {
    const createStripeIntent = this.fns.httpsCallable('createStripeIntent');
    return createStripeIntent({
      subscriptionPriceId,
      paymentMethodId: paymentMethodId
      // paymentMethodId: "pm_card_authenticationRequiredOnSetup"
    });
  }

  async confirmCardPayment(subscriptionPriceId: string, paymentIntent, coupon?:string): Promise<any> {
    paymentIntent = paymentIntent.intent;
    const checkoutSubscriptionWithCard = this.fns.httpsCallable('checkoutSubscriptionWithCard');
    if ((paymentIntent.status === 'requires_source_action' || paymentIntent.status === 'requires_action') &&
      paymentIntent?.next_action?.type === 'use_stripe_sdk') {
        return await this.stripeService.confirmCardPayment(paymentIntent.client_secret).pipe(take(1))
        // return await this.stripeService.confirmCardSetup(paymentIntent.client_secret).pipe(take(1))
        .toPromise()
        .then(async (result) => {
          if (result.error) {
            return result
          } else {

            if(coupon) {
              return await checkoutSubscriptionWithCard({
                subscriptionPriceId,
                intent: paymentIntent.id,
                coupon: coupon
              }).toPromise();
            } else {
              return await checkoutSubscriptionWithCard({
                subscriptionPriceId,
                intent: paymentIntent.id
              }).toPromise();
            }


          }
        });
    }
    if(coupon) {
      return await checkoutSubscriptionWithCard({
        subscriptionPriceId,
        intent: paymentIntent.id,
        coupon: coupon
      }).toPromise();
    } else {
      return await checkoutSubscriptionWithCard({
        subscriptionPriceId,
        intent: paymentIntent.id
      }).toPromise();
    }
  }


  // SUBSCRIPTION VIA PAYPAL
  // #############################################################

  async createPayPalBillingAgreement(subscriptionPriceId: string, usage:string, coupon?:string): Promise<any> {
    const createPayPalBillingAgreement = this.fns.httpsCallable('createPayPalBillingAgreementToken');
    if(coupon) {
      return await createPayPalBillingAgreement({
        subscriptionPriceId,
        successUrl: window.location.origin + "/subscription/finish",
        usage: usage,
        coupon: coupon
      }).toPromise();
    } else {
      return await createPayPalBillingAgreement({
        subscriptionPriceId,
        successUrl: window.location.origin + "/subscription/finish",
        usage: usage
      }).toPromise();
    }

  }


  // SUBSCRIPTION VIA COUPON
  // #############################################################

  async checkoutSubscriptionWithCoupon(subscriptionPriceId: string, coupon:string): Promise<any> {
    const checkoutSubscriptionWithCoupon = this.fns.httpsCallable('checkoutSubscriptionWithCoupon');
    return await checkoutSubscriptionWithCoupon({
      subscriptionPriceId: subscriptionPriceId,
      coupon: coupon
    }).toPromise();
  }


  // CHANGE SUBSCRIPTION
  // #############################################################
  async changeSubscription(subscriptionPriceId: string): Promise<any> {
    const changeSubscription = this.fns.httpsCallable('changeSubscription');
    return await changeSubscription({
      price: subscriptionPriceId
    }).toPromise();
  }


  async checkoutExtraCoinsPackages(cart): Promise<any> {
    const checkoutExtraCoinsPackages = this.fns.httpsCallable('checkoutExtraCoinsPackages');
    return await checkoutExtraCoinsPackages(cart).toPromise();
  }


  // CANCEL SUBSCRIPTION
  // #############################################################
  async cancelSubscription(): Promise<any> {
    const cancelSubscription = this.fns.httpsCallable('cancelSubscription');
    return await cancelSubscription({}).toPromise();
  }

  // PAUSE SUBSCRIPTION
  // #############################################################
  async pauseSubscription(): Promise<any> {
    const pauseSubscription = this.fns.httpsCallable('pauseSubscription');
    return await pauseSubscription({}).toPromise();
  }

  // REACTIVATE SUBSCRIPTION
  // #############################################################
  async reactivateSubscription(): Promise<any> {
    const reactivateSubscription = this.fns.httpsCallable('reactivateSubscription');
    return await reactivateSubscription({}).toPromise();
  }
  

  // ADD CARD PAYMENT METHOD IF MISSING
  // #############################################################
  async addCardPaymentMethod(token:string): Promise<any> {
    const addCardPaymentMethod = this.fns.httpsCallable('addCardPaymentMethod');
    return await addCardPaymentMethod({
      token: token
    }).toPromise();
  }

  // // UPDATE CARD PAYMENT METHOD IF MISSING
  // // #############################################################
  // async updateCardPaymentMethod(token:string): Promise<any> {
  //   const updateCardPaymentMethod = this.fns.httpsCallable('addCardPaymentMethod');
  //   return await updateCardPaymentMethod({
  //     token: token,
  //     replacePrimaryMethod: true
  //   }).toPromise();
  // }

  // ADD PAYPAL PAYMENT METHOD IF MISSING
  // #############################################################

  async addPayPalPaymentMethod(subscriptionPriceId: string): Promise<any> {
    const createPayPalBillingAgreement = this.fns.httpsCallable('createPayPalBillingAgreementToken');
    return await createPayPalBillingAgreement({
      subscriptionPriceId,
      successUrl: window.location.origin + "/account/",
      cancelRedirect: window.location.origin + "/account/paymentinfo",
      usage: "addPaymentMethod"
    }).toPromise();

  }


  async getPaymentInfo(): Promise<Observable<any>> {
    const getPaymentMethod = this.fns.httpsCallable('getPaymentMethod');
    return await getPaymentMethod({}).toPromise();
  }

}


