import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sub-navigation',
  templateUrl: './sub-navigation.component.html',
  styleUrls: ['./sub-navigation.component.scss']
})
export class SubNavigationComponent  {

  @Input() sticky: boolean = false;
  @Input() showFilter: boolean = false;
  @Output() filterDataOutput: EventEmitter<any> = new EventEmitter<any>(); 

  activeItem:string = "";

  constructor(    
    private activatedRoute: ActivatedRoute,    
  ) {
    this.activeItem = this.activatedRoute?.snapshot?.url[1]?.path;
  }

  setFilter(event) {
    this.filterDataOutput.emit(event);
  }


}
