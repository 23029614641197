import { DatePipe } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-event-timeline',
  templateUrl: './event-timeline.component.html',
  styleUrls: ['./event-timeline.component.scss']
})
export class EventTimelineComponent {
  
  @Input() events: Array<any>;
  showtimesList = [];

  constructor(
    public datepipe: DatePipe
  ){}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['events']) {
      this.structureEvents();
    }
  }

  findCheapestPrice(priceCategories, channel) {
    let cheapestPricecategory;

    switch(channel){
      case "cinetixx":
        cheapestPricecategory = priceCategories.find(obj => obj.category === "Web-Normal");
        return cheapestPricecategory?.coins
      default:
        let smallestItem = 0;
        priceCategories.forEach(element => {
          if (element < smallestItem) {
            smallestItem = element;
          }
        });
        return priceCategories[smallestItem]?.coins
    }
  }


  structureEvents() {
    this.showtimesList = [];

    for (let i = 0; i < this.events?.length; i++) {  

      if(this.events[i]?.data?.eventType !== 'unknown' && this.events[i]?.data?.eventType !== 'kinder') {

      // SET SHOWTIME DAY
      var showtimeDay = new Date(this.events[i]?.data?.datetime);
      showtimeDay.setHours(0,0,0,0);

      // FILTER BY SHOWTIMEDAY
      let dayArray = this.showtimesList.find(x => x.day == showtimeDay.toString());

      // IF SHOWTIME DAY EXISTS
      if(dayArray) {

        // CHECK IF TIMESLOT ALREADY EXISTS
        let resultArr = dayArray.timeslots.find(obj => obj.datetime === this.events[i].data.datetime);

        if(resultArr) {
          resultArr.showtimes.push({
            "eventName": this.events[i]?.data?.eventName,
            "eventImage": this.events[i]?.data?.eventImage,
            "type": this.events[i]?.data?.eventType,
            "cheapestPrice": this.findCheapestPrice(this.events[i]?.data?.priceCategories, this.events[i]?.data?.channel),
            "slug": this.events[i]?.id
          });
        } else {
          this.showtimesList.find(x => x.day == showtimeDay.toString()).timeslots.push({
            "datetime": this.events[i]?.data?.datetime,
            "showtimes": [{
              "eventName": this.events[i]?.data?.eventName,
              "eventImage": this.events[i]?.data?.eventImage,
              "type": this.events[i]?.data?.eventType,
              "cheapestPrice": this.findCheapestPrice(this.events[i]?.data?.priceCategories, this.events[i]?.data?.channel),
              "slug": this.events[i]?.id
            }]
          });
        }

      // IF SHOWTIME DAY DOES NOT EXISTS
      } else { 
        this.showtimesList.push({
          "day" : showtimeDay,
          "timeslots" : [{
            "datetime": this.events[i]?.data?.datetime,
            "showtimes": [{
              "eventName": this.events[i]?.data?.eventName,
              "eventImage": this.events[i]?.data?.eventImage,
              "type": this.events[i]?.data?.eventType,
              "cheapestPrice": this.findCheapestPrice(this.events[i]?.data?.priceCategories, this.events[i]?.data?.channel),
              "slug": this.events[i]?.id
            }]
          }]
        });
      }

    }   
    }
  }

  dateCheck(date) {
    let today = new Date();
    today.setHours(0,0,0,0);
    let tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    let showTime = new Date (date);
    // showTime.setHours(0,0,0,0);
    let showTimeCheck = new Date (showTime);
    showTimeCheck.setHours(0,0,0,0);
    if(showTimeCheck.toString() == today.toString()) {
      return "Heute";
    } else if (showTimeCheck.toString() == tomorrow.toString()) {
      return "Morgen";
    } else {
      return this.datepipe.transform(date, 'EEEE, dd.MM.').toString();
    }
  }
  
  getPlaceholder(event, type) {
    event.target.src = "./assets/placeholder_"+type+".png";
  }


}
