import { Injectable } from "@angular/core";
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/compat/auth';
import { Observable, of } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { switchMap, map, catchError, retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from "../models/user";
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { Location } from '@angular/common';

@Injectable()

export class AuthService {

  user$: Observable<any>;
  userAuthData$: any;
  pendingAuthState: boolean = JSON.parse(localStorage.getItem("pendingAuthState"));

  constructor(
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private router: Router,
    private location: Location,
    private http:HttpClient
  ){
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user && user.emailVerified) {
        localStorage.setItem("pendingAuthState","true"); 
        this.user$ = this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        this.pendingAuthState = true;
      } else {
        localStorage.removeItem("pendingAuthState");
        this.pendingAuthState = false;
      }
    });
  }


  getUser() {
    return new Promise<any>((resolve, reject) => {
      if (this.user$) {
        resolve(this.user$) 
        return
      }
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.user$ = this.afs.doc<User>(`users/${user.uid}`).valueChanges();
          resolve(this.user$)
        }
      });
    })
  }

  getUserAuthData() {
    return new Promise<any>((resolve, reject) => {
      if (this.userAuthData$) {
        resolve(this.userAuthData$) 
        return
      }
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.userAuthData$ = {
            'email': user.email,
            'emailVerified': user.emailVerified,
            'photo': user.photoURL
          };
          resolve(this.userAuthData$)
        }
      });
    })
  }


  // SIGN IN 
  // #################################################

  emailSignin(value){
    return new Promise<any>((resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(value.email, value.password)
      .then(
        res => resolve(res), 
        err => reject(err)
      )
    })
  }

  async facebookSignin() {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.signInWithPopup(new FacebookAuthProvider())
      .then(
        res => resolve(res), 
        err => reject(err)
      )
    })
  }

  async googleSignin() {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.signInWithPopup(new GoogleAuthProvider())
      .then(
        res => resolve(res), 
        err => reject(err)
      )
    })
  }


  // SIGN OUT
  // #################################################

  async signOut() {
    localStorage.removeItem("pendingAuthState");
    return await this.afAuth.signOut();
  }


  // SIGN UP
  // #################################################

   async emailSignup(value, tempSelectedProduct:string) {
    const credential = await this.afAuth.createUserWithEmailAndPassword(value.email, value.password);
    credential.user.updateProfile({
      displayName: value.firstName + " " + value.lastName
    }).then( () => {   
      this.sendEmailVerification(credential.user);
      return this.setUserData(credential.user, value.firstName, value.lastName, tempSelectedProduct);    
    });
  }

  sendEmailVerification(user) {
    user.sendEmailVerification({url: window.location.origin + '/subscription/personal-data', handleCodeInApp: false}).then(function() {
      return
    }).catch(function(error) {
      // ERROR HANDLING IS MISSING
      console.log(error);
    });
  }

  async resendEmailVerification(): Promise<boolean> {
    (await this.afAuth.currentUser).sendEmailVerification({url: window.location.origin + '/subscription/personal-data', handleCodeInApp: false}).then(() => {
      return true
    });
    return false
  }


  

  sendPasswordResetEmail(email: string) { 
    return this.afAuth.sendPasswordResetEmail(
      email, 
      { url: window.location.origin + '/start' }); 
  } 
  






















  getSpotifyAuthAccessToken(): Observable<any> {
    const authorizationTokenUrl = `https://accounts.spotify.com/api/token`;
    const body = 'grant_type=client_credentials';
    return this.http.post(authorizationTokenUrl, body, {
      headers: new HttpHeaders({
        Authorization:
          'Basic  ' + btoa("7589c363229e43f2945223d4c75a4327:20ed2ba2bd284b31a55225e2e03d5e78"),
          'Content-Type': 'application/x-www-form-urlencoded;',
      }),
    });
  }
            


  spotifySignin(token) {
    const client_id = "7589c363229e43f2945223d4c75a4327";
    const client_secret = '20ed2ba2bd284b31a55225e2e03d5e78';
    const scopes = "user-read-private user-read-email user-top-read user-read-recently-played playlist-read-private";
    const redirect_uri = "http://localhost:4200/intro/music/results";
    window.location.href = 'https://accounts.spotify.com/authorize' + '?response_type=token' + '&client_id=' + client_id + (scopes ? '&scope=' + encodeURIComponent(scopes) : '') + '&redirect_uri=' + encodeURIComponent(redirect_uri);

 

  // const auth = require('spotify-personal-auth')
  // const SpotifyWebApi = require('spotify-web-api-node')
   
  // // Configure module
  // auth.config({
  //   clientId : "7589c363229e43f2945223d4c75a4327",
  //   clientSecret : '20ed2ba2bd284b31a55225e2e03d5e78',
  //   scope : ['user-modify-playback-state', 'user-top-read'],
  //   path : '/path/to/a/tokens.json'
  // });


  // const api = new SpotifyWebApi()
 

  // auth.token().then(([token, refresh]) => {
  //   // Sets api access and refresh token
  //   api.setAccessToken(token)
  //   api.setRefreshToken(refresh)
   
  //   return api.getMyTopTracks()
  // }).then(data =>
  //   api.play({
  //     uris: data['body']['items'].map(item => item['uri'])
  //   })
  // ).catch(console.log)
  




  // return this.http.get('https://accounts.spotify.com/authorize' +
  // '?response_type=code' +
  // '&client_id=' + client_id +
  // (scopes ? '&scope=' + encodeURIComponent(scopes) : '') +
  // '&redirect_uri=' + encodeURIComponent(redirect_uri))
  //   .pipe(map((data:any) => console.log(data)));








  // const request = require('request');

  // var authOptions = {
  //   url: 'https://accounts.spotify.com/api/token',
  //   headers: new HttpHeaders({
  //     Authorization:
  //       'Basic  ' + btoa("7589c363229e43f2945223d4c75a4327:20ed2ba2bd284b31a55225e2e03d5e78"),
  //       'Content-Type': 'application/x-www-form-urlencoded;',
  //   }),
  //   form: {
  //     grant_type: 'client_credentials'
  //   },
  //   json: true
  // };
  

  // this.http.post(authOptions, function(error, response, body) {
  //   if (!error && response.statusCode === 200) {
  
  //     // use the access token to access the Spotify Web API
  //     var token = body.access_token;
  //     var options = {
  //       url: 'https://api.spotify.com/v1/users/jmperezperez',
  //       headers: {
  //         'Authorization': 'Bearer ' + token
  //       },
  //       json: true
  //     };
  //     request.get(options, function(error, response, body) {
  //       console.log(body);
  //     });
  //   }
  // });





  // return this.http.get('/login', function(req, res) {
  //   var scopes = 'user-read-private user-read-email';
  //   res.redirect('https://accounts.spotify.com/authorize' +
  //     '?response_type=code' +
  //     '&client_id=' + client_id +
  //     (scopes ? '&scope=' + encodeURIComponent(scopes) : '') +
  //     '&redirect_uri=' + encodeURIComponent(redirect_uri));
  //   });

}




getSpotifyUserData(token) {
//       var options = {
//         url: 'https://api.spotify.com/v1/me',
//         headers: {
//           'Authorization': 'Bearer ' + token
//         },
//         json: true
//       };
//       request(options, function(error, response, body) {
//         console.log(body);
//       });
// }
return this.http
.get('https://api.spotify.com/v1/me',{headers: {
  'Authorization': 'Bearer ' + token
}})
.pipe(retry(3), map((data:any) => data));
}    
  


getSpotifyUserArtists(token) {
  return this.http
  .get('https://api.spotify.com/v1/me/top/artists?limit=50',{headers: {
    'Authorization': 'Bearer ' + token
  }})
  .pipe(retry(3), map((data:any) => data));
  }    


getSpotifyUserTracks(token) {
  return this.http
  .get('https://api.spotify.com/v1/me/top/tracks',{headers: {
    'Authorization': 'Bearer ' + token
  }})
  .pipe(retry(3), map((data:any) => data));
  }    
  
  searchSpotify(token, searchterm) {
    return this.http
    .get('https://api.spotify.com/v1/search?q='+searchterm+'&type=album,artist',{headers: {
      'Authorization': 'Bearer ' + token
    }})
    .pipe(retry(3), map((data:any) => data));
    }   



  // var authOptions = {
  //   url: 'https://accounts.spotify.com/api/token',
  //   headers: {
  //     'Authorization': 'Basic ' + (new Buffer('7589c363229e43f2945223d4c75a4327:20ed2ba2bd284b31a55225e2e03d5e78').toString('base64'))
  //   },
  //   form: {
  //     grant_type: 'client_credentials'
  //   },
  //   json: true
  // };
  //   return this.http.post('https://accounts.spotify.com/authorize', authOptions) {

  //   }

  // }

//   var client_id = '7589c363229e43f2945223d4c75a4327'; // Your client id
//   var client_secret = '20ed2ba2bd284b31a55225e2e03d5e78'; // Your secret
//   var redirect_uri = 'http://localhost:4200/account'; // Your redirect uri

//   return this.http.get('/login', function(req, res) {
//     var scopes = 'user-read-private user-read-email';
//     res.redirect('https://accounts.spotify.com/authorize' +
//       '?response_type=code' +
//       '&client_id=' + client_id +
//       (scopes ? '&scope=' + encodeURIComponent(scopes) : '') +
//       '&redirect_uri=' + encodeURIComponent(redirect_uri));
//     });
// }



// request.post(authOptions, function(error, response, body) {
//   if (!error && response.statusCode === 200) {

//     // use the access token to access the Spotify Web API
//     var token = body.access_token;
//     var options = {
//       url: 'https://api.spotify.com/v1/users/jmperezperez',
//       headers: {
//         'Authorization': 'Bearer ' + token
//       },
//       json: true
//     };
//     request.get(options, function(error, response, body) {
//       console.log(body);
//     });
//   }




  private setUserData(user, firstName:string, lastName:string, tempSelectedProduct:string) {
    const userRef = this.afs.doc(`users/${user.uid}`);
    const data = { 
      firstName: firstName,
      lastName: lastName,
      tempSelectedProduct: tempSelectedProduct
    } 
    userRef.set(data, {merge: true}).then(function() {
      // userRef.collection('favorites').doc("Test").set({
      //   'name': "Testname",
      //   'slug': "Test"
      // });
    }).catch(function(error){
      console.log("Error adding subcollections to Firestore: " + error);
    });
    return
  }
  

  // private updateUserData(user,data) {
  //   const userRef = this.afs.doc(`users/${user.uid}`);
  //     userRef.collection('favorites').doc("Test").set({
  //       'name': "Testname",
  //       'slug': "Test"
  //     });
  //   userRef.update(data).then(function() {
  //   }).catch(function(error){
  //     console.log("Error adding subcollections to Firestore: " + error);
  //   });
  //    return
  // }




}