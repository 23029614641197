import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-intro-music',
  templateUrl: './intro-music.component.html',
  styleUrls: ['./intro-music.component.scss']
})
export class IntroMusicComponent implements OnInit {

  userData;
  favArtists: any;
  favTracks: any;
  favGenres: any = [];
  usedSpotifySignIn: boolean = false;
  accessToken:string;
  searchResults: Array<any> = [];

  constructor(    
    public authService: AuthService,
    private activatedRoute: ActivatedRoute
    ) { 

    }


  ngOnInit() {
    this.activatedRoute.fragment.subscribe((fragment: string) => {
      const urlParams = new URLSearchParams(fragment);
      this.accessToken = urlParams.get('access_token');

      if (this.accessToken) {
        this.getSpotifyData(this.accessToken);
      } else {

      }
    });
  }


  getSpotifyData(accessToken) {
    this.usedSpotifySignIn = true;

    this.authService.getSpotifyUserData(accessToken).subscribe(result => {
      this.userData = result;
      console.log(result);
    });
    this.authService.getSpotifyUserArtists(accessToken).subscribe(async result => {
      this.favArtists = result.items;
      await result.items.forEach(async element => {
        await element.genres.forEach(genre => {
          this.favGenres.push({'name':genre});
        });
      });
    });
    this.authService.getSpotifyUserTracks(accessToken).subscribe(result => {
      this.favTracks = result.items;
    });
  }

  connectWithSpoitfy() {
    this.authService.getSpotifyAuthAccessToken().subscribe((result) => {
      this.authService.spotifySignin(result);
    });
  }

  searchSpotify(searchterm) {
    if(this.accessToken) {
      this.authService.searchSpotify(this.accessToken,searchterm).subscribe((result) => {
        console.log(result);
        this.searchResults = result;

      });
    } else {
      this.authService.getSpotifyAuthAccessToken().subscribe((response)=> {
        console.log(response.access_token)
        this.authService.searchSpotify(response.access_token,searchterm).subscribe((result) => {
          this.searchResults = [];
          result.artists.items.forEach(element => {
            this.searchResults.push({'name':element.name});
          });
        
          console.log(this.searchResults);

        });
      });
    }
  }

}
