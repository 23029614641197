import { Component, Inject, OnInit } from '@angular/core';
import { SubscriptionPlan } from 'src/app/models/subscriptionplan';
import { AuthService } from 'src/app/services/auth.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss']
})
export class UpgradeComponent implements OnInit {

  returnUrl: string;
  creditsRequired: number;
  extraCoinsPackages:Array<any> = [];
  subscriptionPlans:Array<any> = [];
  loading: boolean = true;
  subscription;
  productSelected: boolean = false;
  cart: Array<any> = [];
  total = 0;

  constructor(
    private _bottomSheet: MatBottomSheet,
    public authService: AuthService,
    private checkoutService: CheckoutService,
    private route: ActivatedRoute,
  ) { }

  async getSubscriptionsPlans() {
    var dataReceived = false;
    const user = await this.authService.getUser();
    user.subscribe((user) => {   
      this.subscription = user?.subscription;

      this.checkoutService.getSubscriptionPlans().subscribe(result => {
        if(result && !dataReceived) {
          dataReceived = true;
          const userSubscriptionPlan = result.data[this.subscription.plan];


          Object.entries(result.data).forEach(entry => {
            const [key, value] = entry;
            this.subscriptionPlans.push({
              id: key,
              data: value
            });
          });
  

          // result.data.forEach(element => {
          //   let elementCoinsAmount = element.prices[element.id + "-EUR-Monthly"]?.coinsAmount;
          //   let userSubscriptionPlanCoinsAmount = userSubscriptionPlan?.prices[userSubscriptionPlan.id + "-EUR-Monthly"]?.coinsAmount;
          //   if (element.status == "active" && elementCoinsAmount > userSubscriptionPlanCoinsAmount) {
          //     console.log(element);
          //     this.subscriptionPlans.push(element);
          //   }
          // });
 
          this.loading = false;
        }
      });

      // this.checkoutService.getSubscriptionPlans().subscribe(result => {
      //   if(result && !dataReceived) {
      //     dataReceived = true;
      //     const userSubscriptionPlan = result.find(x => x.id == this.subscription.plan);
      //     console.log(userSubscriptionPlan);
      //     result.forEach(element => {
      //       let elementCoinsAmount = element.prices[element.id + "-EUR-Monthly"]?.coinsAmount;
      //       let userSubscriptionPlanCoinsAmount = userSubscriptionPlan?.prices[userSubscriptionPlan.id + "-EUR-Monthly"]?.coinsAmount;
      //       if (element.status == "active" && elementCoinsAmount > userSubscriptionPlanCoinsAmount) {
      //         console.log(element);
      //         this.subscriptionPlans.push(element);
      //       }
      //     });
      //     this.loading = false;
      //   }
      // });
    });
  }


  getExtraCoinsPackages() {
    this.checkoutService.getExtraCoinsPackages().subscribe(result => {
      if(result) {
        Object.entries(result.data).forEach(entry => {
          const [key, value] = entry;
          this.extraCoinsPackages.push({
            id: key,
            data: value
          });
        });
        this.getSubscriptionsPlans();
      }
    });
  }

  // changeSubscription(subscriptionPlan) {
  //   this.productSelected = true;
  //   this.checkoutService.changeSubscription(subscriptionPlan + "-EUR-Monthly").then((result) => {
  //     console.log(result);
  //   }); 
  // }

  updateCart(extraCoinsPackage, quantity:number) {

    console.log(extraCoinsPackage);

    let item = this.cart.find(x => x.id === extraCoinsPackage.id);
    if (item) {
      if (quantity == 0) {
        this.total = this.total-extraCoinsPackage.data.prices[extraCoinsPackage.id + "-EUR"].price/100;
        const index = this.cart.indexOf(item);
        this.cart.splice(index, 1);
      } else {
        if(item.quantity > quantity) {
          this.total = this.total-extraCoinsPackage.data.prices[extraCoinsPackage.id + "-EUR"].price/100;
        } else {
          this.total = this.total+extraCoinsPackage.data.prices[extraCoinsPackage.id + "-EUR"].price/100;
        }
        item.quantity = quantity;
      }
    } else {
      this.cart.push({
        "id":extraCoinsPackage.id,
        "name":extraCoinsPackage.data.name,
        "coinsAmount":extraCoinsPackage.data.prices[extraCoinsPackage.id + "-EUR"].coinsAmount,
        "price":extraCoinsPackage.data.prices[extraCoinsPackage.id + "-EUR"].price/100,
        "priceId": extraCoinsPackage.id+"-EUR",
        "quantity": quantity
      });
      this.total = this.total+extraCoinsPackage.data.prices[extraCoinsPackage.id + "-EUR"].price/100;
    }
  }




  // updateCart(value: number, product): void {
  //   this.amount = 0;
  //   this.sumOfDonation = 0;
  //   const newCartItem = this.ticketOffers.find(x => x.id === product);
  //   let item = this.cart.find(x => x.id === product);
  //   if (!item) {
  //       item = {
  //           'id': newCartItem.id,
  //           'quantity': 0,
  //           'product': newCartItem,
  //       };
  //       this.cart.push(item);
  //   }
  //   item.quantity += value;
  //   this.quantityList.set(product, item.quantity);
  //   this.cart = this.cart.filter(c => c.quantity > 0);
  //   this.cart.forEach(element => {
  //       this.amount = this.amount + (element.quantity * element.product.price);
  //       this.sumOfDonation = this.sumOfDonation + (element.quantity * element.product.speciesProtectionDonation);
  //   });
  // }


  // checkout(selectedProduct) {
  //   // this.checkoutService.addExtraPackage().subscribe(result => {
  //   //   this.router.navigate(["/account"]);
  //   // });
  // }

  openCart() {
    const bottomSheetRef = this._bottomSheet.open(Cart, { data: {cart: this.cart, total: this.total, returnUrl: this.returnUrl} });
    bottomSheetRef.afterDismissed().subscribe((data) => {
      if(data != undefined) {
        
        // this.selectedCityName = data;
        // this.userService.setCurrentCity(data);
      }
    });
  }

  changeSubscription(subscriptionPlan) {
    const bottomSheetRef = this._bottomSheet.open(SubscriptionPlanChange, { data: {subscriptionPlan: subscriptionPlan, returnUrl: this.returnUrl} });
    bottomSheetRef.afterDismissed().subscribe((data) => {
      if(data != undefined) {

      }
    });
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    this.creditsRequired = this.route.snapshot.queryParams['creditsRequired'];
    this.getExtraCoinsPackages();
  }


}



// CART BOTTOM SHEET
// ###############################

@Component({
  selector: 'cart',
  templateUrl: 'cart.html',
})
export class Cart {

  loading:boolean = false;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private _bottomSheetRef: MatBottomSheetRef<Cart>,
    private checkoutService: CheckoutService,
    private router: Router
  ) {}

  dismiss() {
    this._bottomSheetRef.dismiss(this.data);
  }

  //   removeItem(item) {
//     // this.total = this.total-extraCoinsPackage.price;
//     const index = this.data.cart.indexOf(item);
//     this.data.cart.splice(index, 1);
// console.log(this.data.cart.length);

//     if (this.data.cart.length == 0){
//       this._bottomSheetRef.dismiss(this.data);
//     }
    

//   }

  checkout() {
    this.loading = true;
    let cart: Array<any> = [];
    this.data.cart.forEach(item => {
      cart.push({
        package:item.id,
        price:item.priceId,
        quantity:item.quantity
      });
    });

    this.checkoutService.checkoutExtraCoinsPackages(cart).then(result => {
      if(this.data.returnUrl) {
        this.router.navigate([this.data.returnUrl]);
      } else {
        this.router.navigate(["/account"],{ queryParams: { tab: 'subscription' }});
      }
      this._bottomSheetRef.dismiss();
    }).catch(err => {
      switch (err.details?.errorCode) {
        case "PaymentMethodNotFound":
          this._bottomSheetRef.dismiss();
          this.router.navigate(["/account/paymentinfo"],{ queryParams: { case: 'extracredits' }});
          break;
        default:
          console.log(err);
          this._bottomSheetRef.dismiss();
          this.router.navigate(["/account"]);
          break;
      } 
    });
  }
  
}





// SUBSCRIPTION PLAN CHANGE BOTTOM SHEET
// ###############################

@Component({
  selector: 'subscription-plan-change',
  templateUrl: 'subscription-plan-change.html',
})
export class SubscriptionPlanChange {

  loading:boolean = false;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private _bottomSheetRef: MatBottomSheetRef<Cart>,
    private checkoutService: CheckoutService,
    private router: Router
  ) {}

  dismiss() {
    this._bottomSheetRef.dismiss(this.data);
  }

  checkout() {
    this.loading = true;
    this.checkoutService.changeSubscription(this.data.subscriptionPlan.id + "-EUR-Monthly").then((result) => {
      if(this.data.returnUrl) {
        this.router.navigate([this.data.returnUrl]);
      } else {
        this.router.navigate(["/account"],{ queryParams: { tab: 'subscription' }});
      }
      this._bottomSheetRef.dismiss();
    }); 
  }

  
}