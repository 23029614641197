import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-favorite-toggle',
  templateUrl: './favorite-toggle.component.html',
  styleUrls: ['./favorite-toggle.component.scss']
})
export class FavoriteToggleComponent implements OnInit {

  @Input() type: string;
  @Input() objectId: string;

  loading: boolean
  isActive: boolean = false;

  constructor(
    private userService:UserService
  ) { }

  ngOnInit(): void {
  }

  onClick() {
    this.userService.setFavorite(this.type, this.objectId).then(result => {
      if (!result) {
        return;
      }
      this.isActive = !this.isActive;
    });

  }

}
