import { DatePipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-teaser-medium',
  templateUrl: './teaser-medium.component.html',
  styleUrls: ['./teaser-medium.component.scss']
})
export class TeaserMediumComponent implements OnInit {
  
  @Input() event: any;
  @Input() eventtype: string;
  @Input() popularityIndex: string;
  @Input() showPopularityIndex: boolean = false;
  @Input() showRating: boolean = false;
  @Input() showReleasedate: boolean = false;
  @Input() showShowtimes: boolean = false;

  mostRecentShowtime:string;
  image:string;
  specialPrice:any;

  constructor(public datepipe: DatePipe, private eventService:EventService){}

  ngOnInit(): void {
    this.dateCheck();
    this.imageCheck();
    if(this.event.data.hasSpecialPrice) {
      this.getSpecialPrice();
    }
  }

  imageCheck() {
    this.image = this.event?.data?.images?.find(x => x.type == "BACKDROP");
  }

  dateCheck() {
    if(this.event.data.mostRecentShowtime) {
    let today = new Date();
    today.setHours(0,0,0,0);
    let tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    let showTime = new Date (this.event?.data?.mostRecentShowtime);
    showTime.setHours(0,0,0,0);
    if(showTime.toString() == today.toString()) {
      this.mostRecentShowtime = "Heute";
    } else if (showTime.toString() == tomorrow.toString()) {
      this.mostRecentShowtime = "Morgen";
    } else {
        this.mostRecentShowtime = this.datepipe.transform(this.event.data.mostRecentShowtime, 'EEEE, dd.MM.yyyy').toString();
      }
    }
  }

  getPlaceholder(event, type) {
    event.target.src = "./assets/placeholder_"+type+".png";
  }

  getSpecialPrice() {
    this.eventService.getEventShowtimes(this.event.id).subscribe((result:any) => {
      if(result[0].data.hasSpecialPrice) {
        const priceCategoryWithSpecialPrice = result[0].data.priceCategories.find(item => item.specialPrice);
        this.specialPrice = priceCategoryWithSpecialPrice.specialPrice;
      }
    });
  }

}
