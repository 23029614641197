import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ProductSelectionBottomSheet } from 'src/app/components/checkout/product-selection-bottomsheet/product-selection-bottomsheet.component';
import { SubscriptionPlan } from 'src/app/models/subscriptionplan';
import SwiperCore, { SwiperOptions, Mousewheel, Navigation, Pagination, Scrollbar, A11y, FreeMode } from 'swiper';
SwiperCore.use([Mousewheel,FreeMode]);

@Component({
  selector: 'app-product-overview',
  templateUrl: './product-overview.component.html',
  styleUrls: ['./product-overview.component.scss']
})
export class ProductOverviewComponent implements OnInit {

  subscriptionPlans:Array<any> = [];
  selectedSubscriptionPlanId: string = "single";
  loading:boolean = false;
  config: SwiperOptions;

  constructor(    
    private _location: Location,
    public authService: AuthService,
    public checkoutService: CheckoutService,
    private _bottomSheet: MatBottomSheet
  ) { }

  ngOnInit(): void {
    this.getSubscriptionsPlans();

    let slidesPerViewXS = 2;
    let slidesPerViewMD = 4;
    let slidesPerViewLG = 6;
    
    this.config = {
      // slidesPerView: this.slides,

      breakpoints: {
        // when window width is >= 320px
        320: {
         slidesPerView: slidesPerViewXS,
         spaceBetween: 20
        },
        // when window width is >= 480px
        480: {
         slidesPerView: slidesPerViewMD,
         spaceBetween: 30
        },
        // when window width is >= 640px
        640: {
         slidesPerView: slidesPerViewLG,
         spaceBetween: 20
        }
      },

      spaceBetween: 20,
      navigation: true,
      mousewheel: {
        forceToAxis: true,
      },
      effect:"coverflow",
      freeMode: true
    };
  }

  backLink() {
    this._location.back();
  }

  getSubscriptionsPlans() {
    this.checkoutService.getSubscriptionPlans().subscribe(result => {
      this.loading = false;
      if(result) {
        Object.entries(result.data).forEach((entry:any) => {
          const [key, value] = entry;
          this.subscriptionPlans.push({
            id: key,
            sort: value.maxPersons,
            data: value
          });
        });
        this.subscriptionPlans.sort(function(a, b) {
          var keyA = a.sort,
            keyB = b.sort;
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
      }
    });
  }

  changeProduct(subscriptionPlanIdNew:string) {
    this.selectedSubscriptionPlanId = subscriptionPlanIdNew;
    localStorage.setItem('selectedSubscriptionPlanId', subscriptionPlanIdNew);
  }

}
