import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-number-picker',
  templateUrl: './number-picker.component.html',
  styleUrls: ['./number-picker.component.scss']
})
export class NumberPickerComponent {

  @Input() quantity: number = 0;
  @Output() quantityOutput: EventEmitter<number> = new EventEmitter<number>(); 

  constructor() { }

  add() {
    this.quantity = this.quantity +1;
    this.quantityOutput.emit(this.quantity);
  }

  remove() {
    if(this.quantity > 0) {
      this.quantity = this.quantity -1;
      this.quantityOutput.emit(this.quantity);
    }
  }

}
