import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-meta-navigation',
  templateUrl: './meta-navigation.component.html',
  styleUrls: ['./meta-navigation.component.scss']
})
export class MetaNavigationComponent implements OnInit {

  userAuthData$;

  constructor(
    public authService: AuthService
  ) { }

  async ngOnInit(): Promise<void> {
    this.userAuthData$ = await this.authService.getUserAuthData();
  }

}
