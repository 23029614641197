import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-seat-selector',
  templateUrl: './seat-selector.component.html',
  styleUrls: ['./seat-selector.component.scss']
})
export class SeatSelectorComponent  {
  
  // selectedSeat:string;
  loading:boolean = true;
  zoomFactor = 1;
  selectedSeats: Array<any> = [];

  @Input() channel: string;
  @Input() priceCategories: any;
  @Input() seatingPlan: any;
  @Input() numberTickets: number = 0.6;
  @Output() seatSelection: EventEmitter<Array<any>> = new EventEmitter<Array<any>>(); 

  constructor() { }

  // ngOnInit(): void {
  //   if (this.seatingPlan != undefined) {
  //     this.loading= false;
  //     console.log(this.seatingPlan);
  //   }
  // }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['seatingPlan']) {
      switch (this.seatingPlan.channel) {
        case 'muenchenticket':
          this.zoomFactor = 0.2;
          break;
        default:
          this.zoomFactor = 0.6;
          break;
      }
      this.loading= false;
    }
  }

  selectSector(sectorId:string) {
    this.selectedSeats = [];
    for (let i = 0; i < this.numberTickets; i++) {
      this.selectedSeats.push({sectionId:sectorId});
    }
    this.seatSelection.emit(this.selectedSeats);    
  }


  selectSeat(seat, sectorId:string) {
    if (seat.isAvailable) {
      if (this.selectedSeats.length < this.numberTickets) {
        this.selectedSeats.push({seat,sectorId});
      } else {
        this.selectedSeats.shift();
        this.selectedSeats.push({seat:seat,sectionId:sectorId});
      }
      this.seatSelection.emit(this.selectedSeats);
    }
  }

  isSeatAvailable(seatId:string):boolean {
    if(this.selectedSeats.find(x => x.seat?.id == seatId)){return true}
    return false;
  }

  isSectorAvailable(sectorId:string):boolean {
    if(this.selectedSeats.find(x => x.sectionId == sectorId)){return true}
    return false;
  }

}
