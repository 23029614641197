import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SwiperOptions } from 'swiper';
import SwiperCore, { EffectCards } from "swiper";

SwiperCore.use([EffectCards]);

@Component({
  selector: 'app-event-slider',
  templateUrl: './event-slider.component.html',
  styleUrls: ['./event-slider.component.scss']
})
export class EventSliderComponent implements OnInit {
  
  @Input() headline: string;
  @Input() events: Array<any>;
  type: string = this.activatedRoute.snapshot.url[0].path;
  config: SwiperOptions = {

    grabCursor: true,
    effect: 'cards',
  };

  constructor(    
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
  }

}
