import { Component, Input, OnInit } from '@angular/core';
import SwiperCore, { SwiperOptions, Mousewheel, Navigation, Pagination, Scrollbar, A11y, FreeMode } from 'swiper';
SwiperCore.use([Mousewheel,FreeMode]);

@Component({
  selector: 'app-content-section',
  templateUrl: './content-section.component.html',
  styleUrls: ['./content-section.component.scss']
})
export class ContentSectionComponent implements OnInit {
  
  @Input() events: Array<any>;
  @Input() type: string;
  @Input() showPopularityIndex: boolean = false;
  @Input() slides: number;
  @Input() showMoreRoute: string;

  
  config: SwiperOptions;

  constructor() { }

  ngOnInit(): void {

    let slidesPerViewXS = 2;
    let slidesPerViewMD = 4;
    let slidesPerViewLG = 6;

    if(this.type != 'movie') {
       slidesPerViewXS = 1;
       slidesPerViewMD = 2;
       slidesPerViewLG = 3;
    }

    this.config = {
      // slidesPerView: this.slides,

      breakpoints: {
        // when window width is >= 320px
        320: {
         slidesPerView: slidesPerViewXS,
         spaceBetween: 20
        },
        // when window width is >= 480px
        480: {
         slidesPerView: slidesPerViewMD,
         spaceBetween: 30
        },
        // when window width is >= 640px
        640: {
         slidesPerView: slidesPerViewLG,
         spaceBetween: 20
        }
      },

      spaceBetween: 20,
      navigation: true,
      mousewheel: {
        forceToAxis: true,
      },
      freeMode: true
    };
  }

  getBackdropImage(images:Array<any>) {
    const backdropImage = images.find(item => item.type === 'BACKDROP');
    if(backdropImage) {
      return backdropImage.url;
    }
    const posterImage = images.find(item => item.type === 'POSTER');
    if(posterImage) {
      return posterImage.url;
    }
    const thumbnailImage = images.find(item => item.type === 'THUMBNAIL');
    if(thumbnailImage) {
      return thumbnailImage.url;
    }
    return images[0].url;
  }


}
