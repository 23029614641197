import { BrowserModule }  from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from './../environments/environment';
import { MainpageLayoutComponent } from './layouts/mainpage-layout/mainpage-layout.component';
import { SubpageLayoutComponent } from './layouts/subpage-layout/subpage-layout.component';
import { ModalLayoutComponent } from './layouts/modal-layout/modal-layout.component';
import { StartComponent } from './views/start/start.component';
import { ActionboxComponent } from './components/general/actionbox/actionbox.component';
import { MainNavigationComponent } from './components/general/navigation/main-navigation/main-navigation.component';
import { SubNavigationComponent } from './components/general/navigation/sub-navigation/sub-navigation.component';
import { MovieComponent } from './views/categories/movie/movie.component';
import { MusicComponent } from './views/categories/music/music.component';
import { SportComponent } from './views/categories/sport/sport.component';
import { TheaterComponent } from './views/categories/theater/theater.component';
import { ExhibitionComponent } from './views/categories/exhibition/exhibition.component';
import { StageComponent } from './components/teasers/stage/stage.component';
import { HeaderComponent, CitySelector } from './components/general/header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TeaserSmallComponent } from './components/teasers/teaser-small/teaser-small.component';
import { TeaserMediumComponent } from './components/teasers/teaser-medium/teaser-medium.component';
import { TeaserLargeComponent } from './components/teasers/teaser-large/teaser-large.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MetaNavigationComponent } from './components/general/navigation/meta-navigation/meta-navigation.component';
import { EventComponent } from './views/detail/event/event.component';
import { PersonComponent } from './views/detail/person/person.component';
import { LocationComponent } from './views/detail/location/location.component';
import { ParallaxDirective } from './parallax.directive';
import { SearchComponent } from './views/search/search.component';
import { DatePipe, registerLocaleData } from '@angular/common';
import { GenreComponent, GenreSelectorBottomSheet } from './views/detail/genre/genre.component';
import { OutdoorComponent } from './views/categories/outdoor/outdoor.component';
import { HttpClientModule } from '@angular/common/http';
import { SignInComponent } from './views/sign-in/sign-in.component';
import { SignUpComponent } from './views/checkout/sign-up/sign-up.component';
import { IntroComponent } from './views/intro/intro.component';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './guard/auth.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountComponent } from './views/account/account.component';
import { IntroMusicComponent } from './views/intro/intro-music/intro-music.component';
import { IntroMovieComponent } from './views/intro/intro-movie/intro-movie.component';
import { IntroCultureComponent } from './views/intro/intro-culture/intro-culture.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { IntroCompleteComponent } from './views/intro/intro-complete/intro-complete.component';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { IntroMusicResultsComponent } from './views/intro/intro-music-results/intro-music-results.component';
import { DetailpageLayoutComponent } from './layouts/detailpage-layout/detailpage-layout.component';
import { MapComponent } from './components/general/map/map.component';
import { EventTabsComponent } from './components/events/event-tabs/event-tabs.component';
import { EventCalendarComponent } from './components/events/event-calendar/event-calendar.component';
import { EventListComponent } from './components/events/event-list/event-list.component';
import { ProductOverviewComponent } from './views/checkout/product-overview/product-overview.component';
import { FooterComponent } from './components/general/footer/footer.component';
import { HeaderSubpageComponent } from './components/general/header-subpage/header-subpage.component';
import { PaymentComponent } from './views/checkout/payment/payment.component';
import { EventSliderComponent } from './components/events/event-slider/event-slider.component';
import { PersonalDataComponent } from './views/checkout/personal-data/personal-data.component';
import { EmailCheckComponent } from './views/checkout/email-check/email-check.component';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxStripeModule } from 'ngx-stripe';
import { LoadingBoxComponent } from './components/general/loading-box/loading-box.component';
import { ConfirmationComponent } from './views/checkout/confirmation/confirmation.component';
import { BookingMovieSeatComponent } from './views/booking/booking-movie-seat/booking-movie-seat.component';
import { BookingLayoutComponent } from './layouts/booking-layout/booking-layout.component';
import { SeatSelectorComponent } from './components/booking/seat-selector/seat-selector.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatSliderModule } from '@angular/material/slider';
import { AccountBalanceComponent } from './components/account/account-balance/account-balance.component';
import { FavoriteToggleComponent } from './components/general/favorite-toggle/favorite-toggle.component';
import { ModalLayoutNewComponent } from './layouts/modal-layout-new/modal-layout-new.component';
import { ProductSelectionBottomSheet } from './components/checkout/product-selection-bottomsheet/product-selection-bottomsheet.component'; 
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/compat/database';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { TicketComponent } from './components/booking/ticket/ticket.component';
import { BookingConfirmationComponent } from './views/booking/booking-confirmation/booking-confirmation.component';
import { BookingSummaryComponent } from './views/booking/booking-summary/booking-summary.component';
import localeDe from '@angular/common/locales/de';
import { SwiperModule } from 'swiper/angular';
import { ActionboxRecommendationsComponent } from './components/general/actionbox/actionbox-recommendations/actionbox-recommendations.component';
import { ActionboxShowtimesComponent } from './components/general/actionbox/actionbox-showtimes/actionbox-showtimes.component';
import { Cart, SubscriptionPlanChange, UpgradeComponent } from './views/checkout/upgrade/upgrade.component';
import { ActionboxMapComponent } from './components/general/actionbox/actionbox-map/actionbox-map.component';
import { NgAisModule } from 'angular-instantsearch';
import { SubscriptionTeaserComponent } from './components/teasers/subscription-teaser/subscription-teaser.component';
import { ContentSectionComponent } from './components/general/content-section/content-section.component';
import { EventTimelineComponent } from './components/events/event-timeline/event-timeline.component';
import { ChangeComponent } from './views/checkout/change/change.component';
import { NumberPickerComponent } from './components/checkout/number-picker/number-picker.component';
import { CancelComponent } from './views/checkout/cancel/cancel.component';
import { BookingDetailComponent } from './views/booking/booking-detail/booking-detail.component';  
import { MatDialogModule } from '@angular/material/dialog';
import { NgxBarcodeModule } from '@greatcloak/ngx-barcode';
import { ShowMoreComponent } from './components/teasers/show-more/show-more.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { PasswordComponent } from './views/password/password.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { FilterComponent, FilterSettingsBottomSheet } from './components/general/filter/filter.component';
import { TodayComponent } from './views/categories/today/today.component';
import { PauseComponent } from './views/checkout/pause/pause.component';
import { BookingComponent } from './views/booking/booking/booking.component';
import { OthersComponent } from './views/categories/others/others.component';
import { ReactivateComponent } from './views/checkout/reactivate/reactivate.component';
import { WalkthroughComponent } from './views/checkout/walkthrough/walkthrough.component';
import { LocationTeaserComponent } from './components/teasers/location-teaser/location-teaser.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AddPaymentMethodComponent } from './views/checkout/add-payment-method/add-payment-method.component';
import { SignOutComponent } from './views/sign-out/sign-out.component';
import { BookingLogoutPreviewComponent } from './views/booking/booking-logout-preview/booking-logout-preview.component';
import { NgAisRangeSliderModule } from 'angular-instantsearch';

registerLocaleData(localeDe, 'de');
const dateFormat = {
    parse: {
        dateInput: 'DD.MM.YYYY',
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'DD.MM.YYYY',
        monthYearA11yLabel: 'MMMM YYYY',
    }
};

@NgModule({
    declarations: [
        AppComponent,
        MainpageLayoutComponent,
        SubpageLayoutComponent,
        ModalLayoutComponent,
        StartComponent,
        ActionboxComponent,
        MainNavigationComponent,
        SubNavigationComponent,
        MovieComponent,
        MusicComponent,
        SportComponent,
        TheaterComponent,
        ExhibitionComponent,
        StageComponent,
        HeaderComponent,
        TeaserSmallComponent,
        TeaserMediumComponent,
        TeaserLargeComponent,
        CitySelector,
        GenreSelectorBottomSheet,
        MetaNavigationComponent,
        EventComponent,
        PersonComponent,
        LocationComponent,
        ParallaxDirective,
        SearchComponent,
        GenreComponent,
        OutdoorComponent,
        SignInComponent,
        SignUpComponent,
        PasswordComponent,
        IntroComponent,
        AccountComponent,
        IntroMusicComponent,
        IntroMovieComponent,
        IntroCultureComponent,
        IntroCompleteComponent,
        IntroMusicResultsComponent,
        DetailpageLayoutComponent,
        MapComponent,
        EventTabsComponent,
        EventCalendarComponent,
        EventListComponent,
        ProductOverviewComponent,
        FooterComponent,
        HeaderSubpageComponent,
        PaymentComponent,
        EventSliderComponent,
        PersonalDataComponent,
        EmailCheckComponent,
        LoadingBoxComponent,
        ConfirmationComponent,
        BookingMovieSeatComponent,
        BookingLayoutComponent,
        SeatSelectorComponent,
        AccountBalanceComponent,
        FavoriteToggleComponent,
        ModalLayoutNewComponent,
        ProductSelectionBottomSheet,
        Cart,
        SubscriptionPlanChange,
        TicketComponent,
        BookingConfirmationComponent,
        BookingSummaryComponent,
        ActionboxRecommendationsComponent,
        ActionboxShowtimesComponent,
        UpgradeComponent,
        ActionboxMapComponent,
        SubscriptionTeaserComponent,
        ContentSectionComponent,
        EventTimelineComponent,
        ChangeComponent,
        NumberPickerComponent,
        CancelComponent,
        BookingDetailComponent,
        ShowMoreComponent,
        PageNotFoundComponent,
        FilterComponent,
        FilterSettingsBottomSheet,
        TodayComponent,
        PauseComponent,
        BookingComponent,
        OthersComponent,
        ReactivateComponent,
        WalkthroughComponent,
        LocationTeaserComponent,
        AddPaymentMethodComponent,
        SignOutComponent,
        BookingLogoutPreviewComponent
    ],
    imports: [
        AppRoutingModule,
        MatIconModule,
        NgbModule,
        BrowserAnimationsModule,
        AngularFireModule.initializeApp(environment.firebase),
        // AngularFirestoreModule.enablePersistence(),
        AngularFirestoreModule,
        AngularFireAuthModule,
        AngularFireStorageModule,
        AngularFireDatabaseModule,
        AngularFireFunctionsModule,
        AngularFireAnalyticsModule,
        MatBottomSheetModule,
        MatDialogModule,
        MatFormFieldModule,
        MatButtonModule,
        MatChipsModule,
        MatListModule,
        MatTabsModule,
        MatSnackBarModule,
        BrowserModule,
        MatInputModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatCheckboxModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatSelectModule,
        MatSliderModule,
        FormsModule,
        MatAutocompleteModule,
        MatRadioModule,
        SwiperModule,
        NgxBarcodeModule,
        NgxQRCodeModule,
        DragDropModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgAisModule.forRoot(), 
        NgAisRangeSliderModule,
        NgxStripeModule.forRoot(environment.stripeKey),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately'
        })
    ],
    providers: [
        AuthService,
        AuthGuard,
        { provide: LOCALE_ID, useValue: 'de' },
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        { provide: MAT_DATE_FORMATS, useValue: dateFormat },
        DatePipe,
        { provide: REGION, useValue: 'europe-west1' },
        // { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['http://localhost', 9099] : undefined },
        { provide: USE_DATABASE_EMULATOR, useValue: environment.useEmulators ? ['http://localhost', 9000] : undefined },
        // { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined },
        { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
