import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-header-subpage',
  templateUrl: './header-subpage.component.html',
  styleUrls: ['./header-subpage.component.scss']
})
export class HeaderSubpageComponent implements OnInit {

  @Input() title:string;
  @Input() logout:boolean;
  @Input() search:boolean;
  @Input() account:boolean;
  @Input() backRoute:string;
  userAuthData$;

  constructor(    
    private _location: Location,    
    private router: Router,
    public authService: AuthService,
    private _snackBar: MatSnackBar
  ) { }

  async ngOnInit(): Promise<void> {
    this.userAuthData$ = await this.authService.getUserAuthData();
  }

  backLink() {
    if(this.backRoute == undefined) {
      this._location.back();
    } else {
      this.router.navigate([this.backRoute]);
    }
  }

  openSearch() {
    console.log("SEARCH");
    this.router.navigate(['/search']);
  }

  signOut() {
    this.router.navigate([ "/logout"]);
  }



}
