import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-booking-confirmation',
  templateUrl: './booking-confirmation.component.html',
  styleUrls: ['./booking-confirmation.component.scss']
})
export class BookingConfirmationComponent implements OnInit {

  @Input() ticket: any;

  constructor() { }

  ngOnInit(): void {
  }

  getLastBooking() {
    // this.eventService.getBook
  }

}
