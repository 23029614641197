import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-actionbox',
  templateUrl: './actionboxnew.component.html',
  styleUrls: ['./actionboxnew.component.scss']
})
export class ActionboxComponent implements OnInit {

  @Input() type: number;
  @Input() category: string;
  @Input() showtimes: Array<any>;
  @Input() events: Array<any>;

  status: boolean = false;
  today: Date;
  tomorrow: Date;
  dayaftertomorrow: Date;
  locations = [];
  showtimeDays = [];
  showtimesList = [];
  showtimesCount = 0;
  nextWeekDays: Array<Date> = [];
  eventCheckout:boolean = false;
  eventCheckoutShowtime;
  eventCheckoutLocation;
  eventCheckoutPriceCategory;
  bookingComplete:boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private eventService: EventService, 
    private router: Router
  ) {}

  toggleActionbox(action) {
    if(action == "open") {
      this.status = true;
    } else {
      this.status = false;
      this.eventCheckout = false;
      this.eventCheckoutShowtime = null;
      this.eventCheckoutLocation = null;
    }
  }

  ngOnInit(): void {


    // TODAY
    this.today = new Date();
    this.today.setHours(0,0,0,0);

    // TOMORROW
    this.tomorrow = new Date(this.today);
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);

    // DAYAFTERTOMORROW
    this.dayaftertomorrow = new Date(this.today);
    this.dayaftertomorrow.setDate(this.dayaftertomorrow.getDate() + 2);

    this.getNextWeekDays();

    this.getShowtimes();
    // this.checkForAvailableDays();

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['showtimes']) {
      this.getShowtimes();
      // this.checkForAvailableDays();
    }
    // if (this.showtimes) {
    //   this.showtimesCount = this.showtimes.length;
    // }

    // console.log(this.showtimesList);
  }


  getLocation(id) {
    const locationItem = this.locations.find(x => x.id == id);
    // console.log(locationItem);

    return location;
  }

  compareDate(date1: Date): number {

    let d1 = new Date(date1);
    d1.setHours(0,0,0,0);

    let same = d1.getTime() === this.today.getTime();
    let same1 = d1.getTime() === this.tomorrow.getTime();
    let same2 = d1.getTime() === this.dayaftertomorrow.getTime();

    if (same) return 0;
    if (same1) return 1;
    if (same2) return 2;

    // Check if the first is less than second
    if (d1 < this.today) return -1;
  }



  async getShowtimes() {
    this.showtimesCount = 0;
    this.showtimesList = [];

    if (this.showtimes) {

    // FOR EACH SHOWTIME
    for (let i = 0; i < this.showtimes.length; i++) {


    // MUSIC SHOWTIMES
    // #####################################
    if(this.category == "music" || this.category == "sport" || this.category == "theater" || this.category == "exhibition" ) {



      this.showtimesCount = this.showtimesCount+1;







    // MOVIE SHOWTIMES
    // #####################################
    } else if (this.category == "movie") {

  
      var date = new Date(this.showtimes[i].data.datetime);
      var now = new Date();

      if (date > now) {
        this.showtimesCount = this.showtimesCount+1;
      }

          // SET SHOWTIME DAY
          var showtimeDay = new Date(this.showtimes[i].data.datetime);
          showtimeDay.setHours(0,0,0,0);

          // FILTER BY SHOWTIMEDAY
          let dayArray = this.showtimesList.find(x => x.day == showtimeDay.toString());

          // IF SHOWTIME DAY EXISTS
          if(dayArray) {




            // CHECK IF LOCATION ALREADY EXISTS AT THE DAY
            // let all = this.showtimesList.reduce((prev, next) => prev.concat(next.locations), []);
            let resultArr = dayArray.locations.find(obj => obj.name === this.showtimes[i].data.location.venue);


            if (resultArr) {



              // LOCATION ALREADY EXISTS AT THE SHOWTIMEDAY
              var temparray = this.showtimesList.find(x => x.day == showtimeDay.toString()).locations;

              await temparray.forEach(element => {

                // ADD FOR CURRENT SHOWTIMELOCATION A NEW SHOWTIME
                if(element.name == this.showtimes[i].data.location.venue) {

                  var date = new Date(this.showtimes[i].data.datetime);
                  var now = new Date();
                  // this.showtimesCount = this.showtimesCount+1;


                  if (date > now) {
                    element.showtimes.push({
                      "datetime": this.showtimes[i].data.datetime,
                      "showtimeId": this.showtimes[i].id
                    });
                  }
                }
              });

            } else {

                // LOCATION NOT EXISTING
                this.showtimesList.find(x => x.day == showtimeDay.toString()).locations.push({
                  "name": this.showtimes[i].data.location.venue,
                  "slug": this.showtimes[i].data.location.slug,
                  "showtimes": [{
                    "datetime": this.showtimes[i].data.datetime,
                    "showtimeId": this.showtimes[i].id
                  }]
                });
            }

          // IF SHOWTIME DAY DOES NOT EXISTS
          } else {

            var date = new Date(this.showtimes[i].data.datetime);
            var now = new Date();
            // this.showtimesCount = this.showtimesCount+1;

            if (date > now) {

              // console.log("----" + showtimeDay);


              this.showtimesList.push({
                "day" : showtimeDay,
                "locations" : [{
                  "name": this.showtimes[i].data.location.venue,
                  "slug": this.showtimes[i].data.location.slug,
                  "showtimes": [{
                    "datetime": this.showtimes[i].data.datetime,
                    "showtimeId": this.showtimes[i].id
                  }]
                }]
              });

      this.showtimesList.sort(function(a,b){
        var da = new Date(a.day).getTime();
        var db = new Date(b.day).getTime();

        return da < db ? -1 : da > db ? 1 : 0
      });

            }
          }

      }
      }


    }
   }




  // checkForAvailableDays() {
  //   // if(this.showtimes) {
  //   //   for (let i = 0; i < this.showtimes.length; i++) {
  //   //     let newdate = new Date(this.showtimes[i].data.datetime);
  //   //     newdate.setHours(0,0,0,0);
  //   //     this.showtimeDays.push(newdate);
  //   //   }
  //   //   this.showtimeDays = this.removeDuplicates(this.showtimeDays);
  //   //   this.getShowtimes();
  //   // }

  //   for (let i = 0; i < 6; i++) {
  //     let newdate = new Date(this.today );
  //     newdate.setDate(newdate.getDate() + i);
  //     this.showtimeDays.push(newdate);
  //     console.log(this.showtimeDays);
  //     console.log(i);
  //   }
  //   this.getShowtimes();

  // }

  getNextWeekDays() {
    for (let i = 0; i < 7; i++) {
      let newdate = new Date(this.today );
      newdate.setDate(newdate.getDate() + i);
      this.showtimesList.push({'day': newdate,'locations': []});
      // console.log(i);
      // console.log(this.showtimesList);
    }
  }

  removeDuplicates(array) {
    let unique = {};
    array.forEach(function(i) {
      if(!unique[i]) {
        unique[i] = true;
      }
    });
    return Object.keys(unique);
  }


  checkoutEvent(eventId,showtimeId,showtimeData,priceCategory) {


    this.eventCheckout = true;
    this.eventCheckoutShowtime = showtimeData.datetime;
    this.eventCheckoutLocation = showtimeData.location;
    this.eventCheckoutPriceCategory = priceCategory;
  }

  bookEvent(showtimeId:string) {
    let currentRoute = this.activatedRoute.snapshot.url[0].path + "/" + this.activatedRoute.snapshot.url[1].path + "/";
    this.router.navigate([currentRoute + "/booking/" + showtimeId + "/seat/"]);
  }


}
