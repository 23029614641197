import { Component, OnInit, Input } from '@angular/core';
import SwiperCore, { SwiperOptions, Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay, A11y]);

@Component({
  selector: 'app-stage',
  templateUrl: './stage.component.html',
  styleUrls: ['./stage.component.scss']
})
export class StageComponent implements OnInit {

  @Input() events: Array<any>;
  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: true,
    pagination: { clickable: true },
    autoplay: {
      delay: 3000,
      pauseOnMouseEnter: true,
    },
  };

  constructor() {}

  ngOnInit(): void {
  }

  getBackdropImage(images:Array<any>) {
    const backdropImage = images.find(item => item.type === 'BACKDROP');
    if(backdropImage) {
      return backdropImage.url;
    }
    const posterImage = images.find(item => item.type === 'POSTER');
    if(posterImage) {
      return posterImage.url;
    }
    const thumbnailImage = images.find(item => item.type === 'THUMBNAIL');
    if(thumbnailImage) {
      return thumbnailImage.url;
    }
    return images[0].url;
  }




}
