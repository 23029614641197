import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-sport',
  templateUrl: './sport.component.html',
  styleUrls: ['./sport.component.scss']
})
export class SportComponent implements OnInit {

  sportPopular;

  constructor(    
    private eventService: EventService  
    ) { }

  ngOnInit(): void {
    this.getSportPopular(); 
   }
 
   getSportPopular() {
     this.eventService.getSportPopular().subscribe((result: any) => {
       this.sportPopular = result;
       console.log(result);
     });
   }
}
