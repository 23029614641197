import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(
    private db: AngularFirestore,
    private fns: AngularFireFunctions 
  ) {} 

  getLocationBySlug(slug) {
    return this.db.collection("locations", ref => ref.where('slug', '==', slug)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data(); 
        const id = a.payload.doc.id;   
        return {id, data};
      }))
    );
  }

  getLocations() {
    return new Promise<any>((resolve, reject) => {
      this.db
        .collection("locations", ref => ref.where("visible","==",true))
        .get()
        .subscribe(res => resolve(res), err => reject(err));
    });
  }

  getAllLocations(type?:string) {
    if (type) {
      return new Promise<any>((resolve, reject) => {
        this.db
          .collection("locations", ref => ref.where('type', '==', type))
          .get()
          .subscribe(res => resolve(res), err => reject(err));
      });
    }
    return new Promise<any>((resolve, reject) => {
      this.db
        .collection("locations", ref => ref.where("type","in",['music', 'movie', 'theater', 'exhibition']))
        .get()
        .subscribe(res => resolve(res), err => reject(err));
    });
  }

  getTopLocations() {
    return new Promise<any>((resolve, reject) => {
      this.db
        .collection("locations", ref => ref.where("visible","==",true).orderBy('eventCount','desc').limit(20))
        .get()
        .subscribe(res => resolve(res), err => reject(err));
    });
  }

  getTopLocationsByType(type?:string) {
    return new Promise<any>((resolve, reject) => {
      this.db
        .collection("locations", ref => ref.where("visible","==",true).where('type', '==', type).orderBy('eventCount','desc').limit(20))
        .get()
        .subscribe(res => resolve(res), err => reject(err));
    });
  }

}