import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventService } from 'src/app/services/event.service';
import { LocationService } from 'src/app/services/location.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-theater',
  templateUrl: './theater.component.html',
  styleUrls: ['./theater.component.scss']
})
export class TheaterComponent implements OnInit {

  theaterPopular;
  genres: Array<any> = [];
  nextTheater: Array<any> = [];
  theaterToday: Array<any> = [];
  theaterAll;
  category: string = this.activatedRoute.snapshot.url[0].path;
  config: SwiperOptions;
  topLocations: any[] = [];

  constructor(    
    private eventService: EventService,     
    private activatedRoute: ActivatedRoute,
    private locationService: LocationService
    ) { }

  ngOnInit(): void {
   this.getTheaterPopular(); 
   this.getTheaterGenres();
   this.getTheaterAll();
   this.getNextTheater();
   this.getTopLocations();


   let slidesPerViewXS = 1;
   let slidesPerViewMD = 2;
   let slidesPerViewLG = 3;

   this.config = {
     // slidesPerView: this.slides,

     breakpoints: {
       // when window width is >= 320px
       320: {
        slidesPerView: slidesPerViewXS,
        spaceBetween: 20
       },
       // when window width is >= 480px
       480: {
        slidesPerView: slidesPerViewMD,
        spaceBetween: 30
       },
       // when window width is >= 640px
       640: {
        slidesPerView: slidesPerViewLG,
        spaceBetween: 20
       }
     },

     spaceBetween: 20,
     navigation: true,
     mousewheel: {
       forceToAxis: true,
     },
     freeMode: true
   };

  }

  getTheaterPopular() {
    this.eventService.getTheaterPopular().subscribe((result: any) => {
      this.theaterPopular = result;
      console.log("POPULAR:" + result);
    });
  }

  // getTheaterComedy() {
  //   this.eventService.getEventsByGenre("comedy", "mostRecentShowtime").subscribe(result => {
  //     this.theaterComedy = result;
  //   });
  // }

  getTheaterAll() {
    this.eventService.getTheaterAll().subscribe(result => {
      this.theaterAll = result;
      console.log(result.length);
      console.log(result);

    });
  }

  getTheaterGenres() {
    this.eventService.getGenres("theater").subscribe((result: any) => {
      this.genres = result;
      console.log("GENRES:" + result);

    });
  }

  getNextTheater() {
    this.eventService.getNextTheater().subscribe((result:any) => {
      this.nextTheater = result;
      let today = new Date();
      today.setHours(23,59,0,0);
      result.forEach(element => {
        if(new Date(element.data.datetime) < today) {
          this.theaterToday.push(element);
        }
      });
      console.log("next:" + result);

    });
  }

  getTopLocations() {
    this.locationService.getTopLocationsByType("theater").then((result:any) => {
      if(result.docs) {
        this.topLocations = result.docs;    console.log("Locations" + this.topLocations);

      }
    });
  }

}
