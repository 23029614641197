import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-teaser-small',
  templateUrl: './teaser-small.component.html',
  styleUrls: ['./teaser-small.component.scss']
})
export class TeaserSmallComponent implements OnInit {
  @Input() event: any;
  @Input() eventtype: string;
  @Input() popularityIndex: string;
  @Input() showPopularityIndex: boolean = false;
  @Input() showRating: boolean = false;
  @Input() releasedate: boolean = false;
  @Input() showtimes: boolean = false;
  
  mostRecentShowtime:String;

  constructor(public datepipe: DatePipe){}

  ngOnInit(): void {
    this.dateCheck();
  }

  dateCheck() {
    if(this.event.data.mostRecentShowtime) {
      let today = new Date();
      today.setHours(0,0,0,0);
      let tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      let showTime = new Date (this.event?.data?.mostRecentShowtime);
      // showTime.setHours(0,0,0,0);
      let showTimeCheck = new Date (showTime);
      showTimeCheck.setHours(0,0,0,0);
      if(showTimeCheck.toString() == today.toString()) {
        this.mostRecentShowtime = "Heute | " + showTime.getHours() + ":" + (showTime.getMinutes()<10?'0':'') + showTime.getMinutes();
      } else if (showTimeCheck.toString() == tomorrow.toString()) {
        this.mostRecentShowtime = "Morgen | " + showTime.getHours() + ":" + (showTime.getMinutes()<10?'0':'') + showTime.getMinutes();
      } else {
        this.mostRecentShowtime = this.datepipe.transform(this.event.data.mostRecentShowtime, 'EEEE, dd.MM.yyyy | HH:mm').toString();
      }
    }
  }

  getPlaceholder(event, type) {
    event.target.src = "./assets/placeholder_"+type+".png";
  }

}
