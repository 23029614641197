import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/services/event.service';
import { MovieEvent } from 'src/app/models/movieevent';
import { MusicEvent } from 'src/app/models/musicevent';
import { TheaterEvent } from 'src/app/models/theaterevent';
import { LocationService } from 'src/app/services/location.service';
import { PersonService } from 'src/app/services/person.service';
import { AuthService } from 'src/app/services/auth.service';
import e from 'express';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

  moviesPopular: MovieEvent[] = [];
  moviesAction: MovieEvent[] = [];
  moviesDrama: MovieEvent[] = [];
  moviesAnimation: MovieEvent[] = [];
  nextEvents: Array<any> = [];
  eventsToday: Array<any> = [];
  eventsWithSpecialPrice: Array<any> = [];
  musicPopular: MusicEvent[] = [];
  theaterPopular: TheaterEvent[] = [];
  theaterNext: TheaterEvent[] = [];
  stageEvents: Array<any> = [];
  topLocations: Array<any> = [];
  topPersons: Array<any> = [];
  moviesUpcomming: Array<any> = [];
  date: Date;
  musicRock: Array<any> = [];
  musicJazz: Array<any> = [];
  musicClassic: Array<any> = [];
  exhibitions: Array<any> = [];
  eventsNew: Array<any> = [];
  config: SwiperOptions;
  hide: boolean = false;


  constructor(
    private eventService: EventService,
    private locationService: LocationService
  ) { 
    let infoPopup = sessionStorage.getItem("infoPopup");
    if (infoPopup) {
      this.hide = true;
    }
  }

  getNextEvents() {
    this.eventService.getNextEvents().subscribe((result:any) => {
      this.nextEvents = result;
      let today = new Date();
      today.setHours(23,59,0,0);
      result.forEach(element => {
        if(new Date(element.data.datetime) < today) {
          this.eventsToday.push(element);
        }
      });
      this.getEventsWithSpecialPrice();
    });
  }
 
  getExhibitions() {
    this.eventService.getExhibitions().subscribe((result: any) => {
      this.exhibitions = result;
      this.getMoviesPopular();
    });
  }

  getMoviesPopular() {
    this.eventService.getMoviesPopular().subscribe((result: any) => {
      this.moviesPopular = result;
      this.getMoviesAction();
    });
  }

  getMoviesAction() {
    this.eventService.getEventsByGenre("action", "mostRecentShowtime").subscribe((result: any) => {
      this.moviesAction = result;
      this.getMoviesDrama();
    });
  }

  getMoviesDrama() {
    this.eventService.getEventsByGenre("drama", "mostRecentShowtime").subscribe((result: any) => {
      this.moviesDrama = result;
      this.getMoviesAnimation();
    });
  }

  getMoviesAnimation() {
    this.eventService.getEventsByGenre("animation", "mostRecentShowtime").subscribe((result: any) => {
      this.moviesAnimation = result;
      this.getMusicPopular();
    });
  }

  getMusicPopular() {
    this.eventService.getMusicPopular().subscribe((result: any) => {
      this.musicPopular = result;
      this.getMusicRock();
    });
  }

  getMusicRock() {
    this.eventService.getEventsByGenre("rock-pop", "mostRecentShowtime", 25).subscribe(result => {
      this.musicRock = result;
      this.getMusicClassic();
    });
  }

  getMusicClassic() {
    this.eventService.getEventsByGenre("klassik", "mostRecentShowtime").subscribe(result => {
      this.musicClassic = result;
      this.getMusicJazz();
    });
  }

  getMusicJazz() {
    this.eventService.getEventsByGenre("jazz", "mostRecentShowtime").subscribe(result => {
      this.musicJazz = result;
      this.getTheaterPopular();
    });
  }

  getTheaterPopular() {
    this.eventService.getTheaterPopular().subscribe((result: any) => {
      this.theaterPopular = result;
      this.getTheaterNext();
    });
  }

  getTheaterNext() {
    this.eventService.getTheaterNext().subscribe((result: any) => {
      this.theaterNext = result;
      this.getTopLocations();
    });
  }


  getTopLocations() {
    this.locationService.getTopLocations().then((result:any) => {
      if(result.docs) {
        this.topLocations = result.docs;
      }
      this.getMoviesUpcomming();
    });
  }

  getMoviesUpcomming() {
    var today = new Date();
    today.setHours(0,0,0,0);
    var lastThursday = new Date(today);
    lastThursday.setDate(lastThursday.getDate() - 7);
    var lastThursdayMilliseconds = lastThursday.getTime();
    var todayMilliseconds = today.getTime();
    this.eventService.getMoviesRelease(lastThursdayMilliseconds,todayMilliseconds).subscribe((result: MovieEvent[]) => {
      this.moviesUpcomming = result;
      this.getStageEvents();
    });

  }

  getStageEvents() {
    if(this.moviesPopular,this.musicPopular,this.theaterPopular) {
      for (let i = 0; i < 5; i++) {
        this.stageEvents.push(this.moviesPopular[i]);
        this.stageEvents.push(this.musicPopular[i]);
        this.stageEvents.push(this.theaterPopular[i]);
      }
    }
  }

  getEventsWithSpecialPrice() {
    this.eventService.getEventsWithSpecialPrice().subscribe((result) => {
      this.eventsWithSpecialPrice = result;
      this.getEventsNew();
    });
  }

  getEventsNew() {
    this.eventService.getEventsNew().subscribe((result) => {
      this.eventsNew = result;
      this.getExhibitions();
    });
  }

  filterContent(filter){
    switch (filter) {
      case "today":
        break;
      case "tomorrow":
          console.log("tomorrow");
          break;
      case "weekend":
          console.log("Weekend");
          break;
      default:
          console.log("No Filter");
          break;
    } 
  }

  setFilter() {
    
  }

  ngOnInit(): void {
    this.getNextEvents();

      let slidesPerViewXS = 1;
      let slidesPerViewMD = 2;
      let slidesPerViewLG = 3;
  
  
      this.config = {
        // slidesPerView: this.slides,
  
        breakpoints: {
          // when window width is >= 320px
          320: {
           slidesPerView: slidesPerViewXS,
           spaceBetween: 20
          },
          // when window width is >= 480px
          480: {
           slidesPerView: slidesPerViewMD,
           spaceBetween: 30
          },
          // when window width is >= 640px
          640: {
           slidesPerView: slidesPerViewLG,
           spaceBetween: 20
          }
        },
  
        spaceBetween: 20,
        navigation: true,
        mousewheel: {
          forceToAxis: true,
        },
        freeMode: true
      };
    }


    disableInfoPopup() {
      this.hide = true;
      sessionStorage.setItem("infoPopup","true");
    }

// getAllEventsWithOutdatedMostRecentShowtime() {
//   this.eventService.showLocationEvents().subscribe(result => {
//     if(result) {
//       result.forEach(evnt => {
//         console.log(evnt);
//       });
//     }
//   });
  
// }

  // getAllMovies() {
  //   let movies = [];

  //   this.eventService.getAllMovies().subscribe(result => {
  //     if(result) {
  //       result.forEach(evnt => {
  //         console.log(evnt.id);
  //       });
  //     }
  //   });


  // }

  // getDuplicate() {
  //   console.log("tES");
  //   let eventSummarys =[];
  //   // this.eventService.getGenres("").subscribe(result => {
  //   //   console.log(result);
  //   // });
  //   this.musicGenres.forEach(element => {
  //   this.eventService.getAllSubEventsFromGenre(element.id).subscribe(result => {

  //     if(result) {
  //       result.forEach(evnt => {
  //         console.log(evnt.genre);

  //         eventSummarys.push(evnt.id);

  //       });
  //     }

  //   });
  //   });
  //   console.log(eventSummarys);
  // }

  // checkEvent() {
  //   let duplicates = [];
  //   this.eventstocheck.forEach(element => {
  //     this.eventService.getEventById(element).subscribe(result => {

  //       if(result.data == undefined) {
  //         duplicates.push(result.id);
  //       }
  //     });
  //     console.log(duplicates);

  //   });
  // }

// getAllShowtimes() {
//   this.eventsList.forEach(event => {
//     this.eventService.getEventShowtimes(event.id).subscribe(result => {
//       if (result) {
//         result.forEach(showtime => {
//           if (showtime.data.eventName != event.name) {
//             // console.log("TREFFER");
//             // console.log(showtime);
//             console.log(event.id);
//             // console.log(event.name);

//           }
//         });
//       }
//     });
//   });
// }


}
