import { Component, OnInit, ViewChild } from '@angular/core';
import { StripeCardNumberComponent, StripeService } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { CheckoutService } from 'src/app/services/checkout.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-add-payment-method',
  templateUrl: './add-payment-method.component.html',
  styleUrls: ['./add-payment-method.component.scss']
})
export class AddPaymentMethodComponent implements OnInit {

  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;
  
  user$;
  userName:string;
  userSubscription:string;
  case:string;
  loading: boolean = false;
  errorMessage:string;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: 'Roboto, "Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'de',
  };

  constructor(    
    public authService: AuthService,
    private checkoutService: CheckoutService,
    private fb: UntypedFormBuilder,
    private stripeService: StripeService,
    private _snackBar: MatSnackBar, 
    private router: Router,  
    private analytics: AngularFireAnalytics,
    private route: ActivatedRoute,
  ) { 

    this.route.queryParams
      .subscribe(params => {
        if(params.case) {
          this.case = params.case;
        }
      }
    );

  }


  async ngOnInit(): Promise<void> {
    this.user$ = await this.authService.getUser();
    this.user$.subscribe((user) => {
      this.userName = user.firstName + " " + user.lastName;
      this.userSubscription = user.subscription.price;
    });
    console.log(this.case);
    this.getPaymentInfo();
  }
  

  addPaymentInfoCard() {
    this.errorMessage = "";
    const name = this.userName;
    this.stripeService
      .createToken(this.card.element, { name })
      .subscribe((result) => {
        if (result.token) {
          this.loading = true;
          this.checkoutService.addCardPaymentMethod(result.token.id).then((result:any) => {
            if(result) {
              this.analytics.logEvent('paymentinfo_card_added');
              switch(this.case) {
                case "extracredits":
                  this.router.navigate(["/account/upgrade"]);
                  break;
                default:
                  this.router.navigate(["/account"]);
                  break;
              } 
            }
          }).catch(err => {
            console.log(err);
          });
        } else if (result.error) {
          this.errorMessage = result.error.message;
        }
      });
  }

  addPaymentInfoPayPal() {
    this.loading = true;
    this.checkoutService.addPayPalPaymentMethod(this.userSubscription).then(result => {
      this.analytics.logEvent('paymentinfo_paypal_added');
      const link = result.links.find(item => item.rel === 'approval_url');
      window.location.href = link.href;
    });
  }

  getPaymentInfo() {
    this.checkoutService.getPaymentInfo().then((result:any) => {
      console.log(result);
    });
  }

  // updatePaymentInfoCard() {
  //   this.errorMessage = "";
  //   const name = this.userName;
  //   this.stripeService
  //     .createToken(this.card.element, { name })
  //     .subscribe((result) => {
  //       if (result.token) {
  //         this.loading = true;
  //         this.checkoutService.updateCardPaymentMethod(result.token.id).then((result:any) => {
  //           console.log(result);
  //         }).catch(err => {
  //           console.log(err);
  //         });
  //       } else if (result.error) {
  //         this.errorMessage = result.error.message;
  //       }
  //     });
  // }

}