import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionPlan } from 'src/app/models/subscriptionplan';
import { AuthService } from 'src/app/services/auth.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { SubscriptionPlanChange } from '../upgrade/upgrade.component';

@Component({
  selector: 'app-change',
  templateUrl: './change.component.html',
  styleUrls: ['./change.component.scss']
})
export class ChangeComponent implements OnInit {
  
  subscriptionPlans:Array<any> = [];
  subscription;
  loading: boolean = true;

  constructor(
    private _bottomSheet: MatBottomSheet,
    public authService: AuthService,
    private checkoutService: CheckoutService,
    private route: ActivatedRoute,
  ) { }
  
  async getSubscriptionsPlans() {
    var dataReceived = false;
    const user = await this.authService.getUser();
    user.subscribe((user) => {
      this.subscription = user?.subscription;
      this.checkoutService.getSubscriptionPlans().subscribe(result => {
        if(result && !dataReceived) {


          dataReceived = true;
          
          const userSubscriptionPlan = result.data[this.subscription.plan]

        Object.entries(result.data).forEach(entry => {
          const [key, value] = entry;

          
          this.subscriptionPlans.push({
            id: key,
            data: value
          });

         

        });

          this.loading = false;


        }
      });
    });
  }

  changeSubscription(subscriptionPlan) {
    const bottomSheetRef = this._bottomSheet.open(SubscriptionPlanChange, { data: {subscriptionPlan: subscriptionPlan} });
    bottomSheetRef.afterDismissed().subscribe((data) => {
      if(data != undefined) {

      }
    });
  }

  ngOnInit(): void {
    this.getSubscriptionsPlans();
  }

}
