import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  loginForm: UntypedFormGroup;
  errorMessage: string = '';
  returnUrl: string;
  hide: boolean;
  user$;
  loading:boolean = true;

  constructor(
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  createForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.required ],
      password: ['',Validators.required]
    });
    this.loading = false;
  }

  async facebookSignin(){
    this.loading = true;
    await this.authService.facebookSignin()
    .then(res => {
      if (res) {
        this.router.navigate([this.returnUrl]);
      }
    })
  }

  async googleSignin(){
    this.loading = true;
    await this.authService.googleSignin()
    .then(async res => {
      if (res) {
        this.user$ = await this.authService.getUser();
        this.user$.subscribe((user) => {
          if(user?.subscription?.status == "active" || user?.subscription?.status == "non_renewing" || user?.subscription?.status == "in_trial") {
            this.router.navigate([this.returnUrl]);
            this.loading = false;
          } else {
            this.router.navigate(["/subscription/personal-data"]);
            this.loading = false;
          }
        });      
      }
    })
  }

  login(value){
    this.loading = true;
    this.authService.emailSignin(value)
    .then(res => {
      this.router.navigate([this.returnUrl]);
      this.loading = false;
    }, err => {
      console.log(err);
      this.loading = false;

      
      if (err.code == "auth/invalid-email") { 
        this.errorMessage = "Bitte gebe eine gültige E-Mail ein.";
      }
      if (err.code == "auth/user-not-found") { 
        this.errorMessage = "Nutzer oder Passwort stimmen nicht überein.";
      }
      if (err.code == "auth/wrong-password") { 
        this.errorMessage = "Nutzer oder Passwort stimmen nicht überein.";
      }
      
    })
  }
  
}
