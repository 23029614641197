import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-booking-summary',
  templateUrl: './booking-summary.component.html',
  styleUrls: ['./booking-summary.component.scss']
})
export class BookingSummaryComponent implements OnInit {

  loading:boolean = true;
  eventId: string;
  showtimeId: string;
  event;
  showtime;

  constructor(    
    private route: ActivatedRoute,    
    private eventService: EventService  
    ) { 
    this.route.paramMap.subscribe( paramMap => {
      this.eventId = paramMap.get('id');
      this.showtimeId = paramMap.get('showtimeid');
    })
  }

  ngOnInit(): void {
    this.getEventDetails();
  }

  getEventDetails() {
    this.eventService.getEvent("movie", this.eventId).subscribe((result:any) => {
      this.event = result.data; 
    });
    this.getShowtimeDetails();     
  }

  getShowtimeDetails(): void {
    this.eventService.getEventShowtimeById(this.eventId, this.showtimeId).subscribe((result:any) => {
      this.showtime = result.data;      console.log(this.showtime);

      this.loading = false;
    });
  }

  bookEvent(){
    
  }

}
