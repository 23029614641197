import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { tap } from 'rxjs/operators';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss']
})
export class PersonalDataComponent implements OnInit {
  
  personalDataForm : UntypedFormGroup;
  loading:boolean = true;
  user: Observable<any>;
  userAuthData$;
  maxDate: Date;

  constructor(    
    public authService: AuthService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private checkoutService: CheckoutService,
    private analytics: AngularFireAnalytics,
    ) {}

  async ngOnInit() {
    const currentYear = new Date().getFullYear();
    this.maxDate = new Date(currentYear - 10, 11, 31);
    this.personalDataForm = this.formBuilder.group({
      salutation: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      birthday: [''],
      address: this.formBuilder.group({
        line1: ['', Validators.required],
        zip: ['', [Validators.required, Validators.min(5)]],
        city: ['', Validators.required],
        country: [{value: 'DE-de', disabled: true}, Validators.required]
      })
    });
    const user = await this.authService.getUser();
    this.userAuthData$ = await this.authService.getUserAuthData();
    user.subscribe((user) => {
      this.user = user
      if(user?.subscription) {
        this.router.navigate(["/start"]);
        return
      } else {
        this.personalDataForm.patchValue(user, {emitEvent:false});  
        setTimeout(() => this.loading = false,2500); 
      }
    });      
  }

  async updateCustomer() {
    var formRawValue = this.personalDataForm.getRawValue();
    if (!this.personalDataForm.valid) return
    this.loading = true;
    this.checkoutService.updateCustomer(
      formRawValue
    ).then((result) => {
      if (result?.status === 'OK') {
        this.analytics.logEvent('personal_data_updated');
        setTimeout(() => this.router.navigate(["/subscription/payment"]),2000); 
      }
    }).catch(err => {
      console.error(err);
      window.location.reload();
    });
  }


}
