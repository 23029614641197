import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CheckoutService } from 'src/app/services/checkout.service';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss']
})
export class CancelComponent implements OnInit {

  loading:boolean = true;
  subscription:any; 

  constructor(
    private checkoutService: CheckoutService,
    private router: Router,
    public authService: AuthService,
    private _snackBar: MatSnackBar,
    private analytics: AngularFireAnalytics
  ) { }

  async getSubscriptionsPlans() {
    const user = await this.authService.getUser();
    user.subscribe((user) => {
      this.subscription = user?.subscription;
          this.loading = false;


    });
  }
  


  pauseSubscription() {
    this.loading = true;
    this.checkoutService.pauseSubscription().then(result => {
      if(result) {
        this.analytics.logEvent('subscription_paused');
        this.router.navigate(["/account"],{ queryParams: { tab: 'subscription' }});
        this._snackBar.open("Mitgliedschaft wurde pausiert.","", {
          duration: 4000,
        });
      }
    });
  }

  cancelSubscription() {
    this.loading = true;
    this.checkoutService.cancelSubscription().then(result => {
      if(result) {
        this.analytics.logEvent('subscription_canceled');
        this.router.navigate(["/account"],{ queryParams: { tab: 'subscription' }});
        this._snackBar.open("Mitgliedschaft wurde gekündigt.","", {
          duration: 4000,
        });
      }
    });
  }

  ngOnInit(): void {
    this.getSubscriptionsPlans();
  }

}
