import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-booking-layout',
  templateUrl: './booking-layout.component.html',
  styleUrls: ['./booking-layout.component.scss']
})
export class BookingLayoutComponent implements OnInit {

  constructor(    
    private _location: Location,
    private route: ActivatedRoute,
    private router: Router,
    ) { }

  ngOnInit(): void {
  }

  backLink() {
    let currentRoute = this.route.children[0].snapshot.url[0].path + "/" + this.route.children[0].snapshot.url[1].path + "/";
    this.router.navigate([currentRoute]);
  }

}
